const style = {
    root: {
      "& .MuiTypography-colorPrimary":{
        color: '#299891'
      },
      "& .MuiTypography-body1":{
        fontWeight: 'bold',
        marginTop: '5px'
      },
      "& .MuiTypography-body2":{
        fontSize: '0.775rem'
      }
    },

    formControl:{
        width: '180px',
        marginRight: '30px'
    },

    link: {
      marginLeft: '15px'
    },
    text:{
      width: '50px'
    },
    comment:{
      marginRight: '25px',
     // marginLeft: '15px',
      width: '150px'
    },
    nameInput:{
      marginRight: '255px',
     // marginLeft: '15px',
      width: '150px'
    }
}

export default style