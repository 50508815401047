import {makeStyles} from '@material-ui/core/styles';

const style= makeStyles((theme) => ({

    root: {
        [theme.breakpoints.up('sm')]: {
       
        },
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column'
        },
    },    
    buttonFab:{
  //      right: 110,
        bottom: 50,
        position: 'fixed'
    },
      fabIcon: {
          fontSize:'2.2rem'
    },
    chip:{
      marginRight: '100px'
    }
}))

export default style