const style = {
    root: {
       "& .MuiFormLabel-root.Mui-focused": {
       // color: '#000'
        }
    },
    cancel:{
        color: 'black'
    },
    agree:{
        color: 'white',
        backgroundColor: '#299891'
    },
    labelInput: {
        fontWeight: 'bold',
        color: '#B0B0B0'
      },
      labelInputFocused:{
        color: '#B0B0B0 !important'
      },  
    field: {
        margin: '10px',
        width: '650px'
    },
    input: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": { 
            },
        "&.Mui-focused fieldset": {
            borderColor: "#299891",
        }
    }
    },
    notchedOutline: {
        borderWidth: "1px",
        borderColor: "yellow !important"
      }
}

export default style