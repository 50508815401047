const Texts = [
    {
        title: 'PEOPLE',
        description: 'Manage people within your organization to achieve great goals'
    }, 

    {
        title: 'USAGE',
        description: 'Review and manage your resource costs'
    },

    {
        title: 'LOGS',
        description: 'Review your application logs'
    },

    {
        title: 'API',
        description: 'Manage your external endpoints'
    }

]

export default Texts
