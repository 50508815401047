/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const handler = /* GraphQL */ `
  query Handler($msg: String) {
    handler(msg: $msg)
  }
`;
export const createNewUser = /* GraphQL */ `
  query CreateNewUser($user: UserInput) {
    createNewUser(user: $user)
  }
`;
export const deleteUser = /* GraphQL */ `
  query DeleteUser($user: UserInput) {
    deleteUser(user: $user)
  }
`;
export const editUser = /* GraphQL */ `
  query EditUser($user: UserInput) {
    editUser(user: $user)
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers {
    listUsers
  }
`;
export const createTable = /* GraphQL */ `
  query CreateTable($table: TableInput) {
    createTable(table: $table)
  }
`;
export const deleteTable = /* GraphQL */ `
  query DeleteTable($table: TableInput) {
    deleteTable(table: $table)
  }
`;
export const editTable = /* GraphQL */ `
  query EditTable($table: TableInput) {
    editTable(table: $table)
  }
`;
export const listTables = /* GraphQL */ `
  query ListTables($table: TableInput) {
    listTables(table: $table)
  }
`;
export const updateTablePartitions = /* GraphQL */ `
  query UpdateTablePartitions($table: TableInput) {
    updateTablePartitions(table: $table)
  }
`;
export const createQuery = /* GraphQL */ `
  query CreateQuery($query: QueryInput) {
    createQuery(query: $query)
  }
`;
export const deleteQuery = /* GraphQL */ `
  query DeleteQuery($query: QueryInput) {
    deleteQuery(query: $query)
  }
`;
export const editQuery = /* GraphQL */ `
  query EditQuery($query: QueryInput) {
    editQuery(query: $query)
  }
`;
export const listQueries = /* GraphQL */ `
  query ListQueries($query: QueryInput) {
    listQueries(query: $query)
  }
`;
export const runQuery = /* GraphQL */ `
  query RunQuery($query: QueryInput) {
    runQuery(query: $query)
  }
`;
export const createDataSet = /* GraphQL */ `
  query CreateDataSet($dataSet: DSInput) {
    createDataSet(dataSet: $dataSet)
  }
`;
export const deleteDataSet = /* GraphQL */ `
  query DeleteDataSet($dataSet: DSInput) {
    deleteDataSet(dataSet: $dataSet)
  }
`;
export const listDwDataSet = /* GraphQL */ `
  query ListDwDataSet($dataSet: DSInput) {
    listDWDataSet(dataSet: $dataSet)
  }
`;
export const getQuicksight = /* GraphQL */ `
  query GetQuicksight($quicksight: QuicksightInput) {
    getQuicksight(quicksight: $quicksight)
  }
`;
export const getStudio = /* GraphQL */ `
  query GetStudio($studio: StudioInput) {
    getStudio(studio: $studio)
  }
`;
export const describeData = /* GraphQL */ `
  query DescribeData($data: DataInput) {
    describeData(data: $data)
  }
`;
export const describeImage = /* GraphQL */ `
  query DescribeImage($data: ImageInput) {
    describeImage(data: $data)
  }
`;
export const getAirflowKpIs = /* GraphQL */ `
  query GetAirflowKpIs {
    getAirflowKPIs
  }
`;
export const getAirflowConst = /* GraphQL */ `
  query GetAirflowConst {
    getAirflowConst
  }
`;
export const listDaGs = /* GraphQL */ `
  query ListDaGs {
    listDAGs
  }
`;
export const getMLflowKpIs = /* GraphQL */ `
  query GetMLflowKpIs {
    getMLflowKPIs
  }
`;
export const getMLflowConst = /* GraphQL */ `
  query GetMLflowConst {
    getMLflowConst
  }
`;
export const listExperiments = /* GraphQL */ `
  query ListExperiments {
    listExperiments
  }
`;
export const getApi = /* GraphQL */ `
  query GetApi($id: ID!) {
    getAPI(id: $id) {
      id
      title
      description
      type
      method
      headers
      body
      authAPI
      filePath
      lambda
      lambdaPath
      targetKey
      targetFilePath
      targetFileType
      targetFileName
      partition
      createdAt
      updatedAt
    }
  }
`;
export const listApIs = /* GraphQL */ `
  query ListApIs(
    $filter: ModelAPIFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAPIs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        type
        method
        headers
        body
        authAPI
        filePath
        lambda
        lambdaPath
        targetKey
        targetFilePath
        targetFileType
        targetFileName
        partition
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDataset = /* GraphQL */ `
  query GetDataset($id: ID!) {
    getDataset(id: $id) {
      id
      name
      description
      type
      format
      query
      path
      refresh
      refreshType
      shared
      users
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listDatasets = /* GraphQL */ `
  query ListDatasets(
    $filter: ModeldatasetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDatasets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        type
        format
        query
        path
        refresh
        refreshType
        shared
        users
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPrivateNote = /* GraphQL */ `
  query GetPrivateNote($id: ID!) {
    getPrivateNote(id: $id) {
      id
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPrivateNotes = /* GraphQL */ `
  query ListPrivateNotes(
    $filter: ModelPrivateNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrivateNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
