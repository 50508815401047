import React, {useState} from 'react';
import {Typography, Box, Slide, Paper, Fab, Button} from '@material-ui/core';
import useStyles from "./styles";
import Table from "../../components/TableRow"
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import Loader from "../../components/Loader/index";
import Message from '../../components/Message/index'
import AuthContext from "../../context/AuthContext";

const columns = [
  {title: "Id", field: "dag_id" },
  {title: "Description",  field: "description" },
  {title: "Active", field: "is_active", type: 'boolean'},
  {title: "Paused", field: "is_paused", type: 'boolean'}
];

export default () => {

  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [constants, setConstants] = React.useState([]);
  const [showLoader, setShowLoader] = React.useState(true);
  const [openMessage, setOpenMessage] = React.useState(false); // Message
  const [message, setMessage] = React.useState(''); // Texto message
  const [messageType, setMessageType] = React.useState(''); // Tipo message
  const {user} = React.useContext(AuthContext)

  React.useEffect(() =>  {
    listDAGs()
    getConstants()
  }, []);

  const listDAGs = async () => {
      const data = await API.graphql(graphqlOperation(queries.listDaGs));
      var resp = JSON.parse(data.data.listDAGs).dags
      setData(resp)
      setShowLoader(false)
  }

  const getConstants = async () => {
    const data = await API.graphql(graphqlOperation(queries.getAirflowConst));
    var resp = JSON.parse(data.data.getAirflowConst).data
    setConstants(resp)
}

  const navToAirflow = async () => {
    window.open(constants.url)
  }

  const onCloseMessage = () => {
    setOpenMessage(false)
  }

  const createMessage = (text, type) => {
    setMessage(text)
    setMessageType(type)
    setOpenMessage(true)
  }

  return (
       <div className = {classes.root}>
        <Message open = {openMessage} message = {message} type = {messageType} onClose={onCloseMessage}/>
        <Loader show = {showLoader}/>
        <div className = {classes.buttonGroup}>
          <Button className ={classes.button} color="secondary" onClick={navToAirflow}>
            AIRFLOW
          </Button>
         </div>
          <Table columns={columns} size={10} data = {data}/>
        </div>
  )
};
