const style = {
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: '5px',
        margin: 0,
      },
      chip: {
        padding: '10px',
        margin: '5px',
      }
}

export default style