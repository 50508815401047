const style = {
    root: {
      ". &ace_content":{
          transform: 'none'
      }
    },
    editor:{
        //minHeight: '650px',
        width: '100% !important'
    },
    buttonFab:{
        right: 110,
        bottom: 50,
        position: 'fixed'
    },
      fabIcon: {
          fontSize:'2.5rem'
    }
}

export default style