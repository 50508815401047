const style = {
    root: {

    },    
      buttonDownload:{
        right: 40,
        bottom: 50,
        position: 'fixed',
        backgroundColor: '#F4F4F4'
      },
      fabIcon: {
          fontSize:'2.5rem'
      },
}

export default style