import style from './style';
import texts from './texts';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import People from '../People/index'
import Usage from '../Usage/index'

const useStyles = makeStyles((theme) => (style));

export default function Organization() {

  const classes = useStyles();
  const [option, setOption] = React.useState(999);

  const handleClick = (index) => {
    setOption(index)
};

  return (
    <>
    {option===999?
    <>
    {texts.map((text, index) => (
    <Card className={classes.root}>
      <CardActionArea onClick = {() => handleClick(index)}>
        <CardContent className={classes.content} >
          <Typography className={classes.title} gutterBottom variant="h5" component="h2">
            {text.title}
          </Typography>
          <Typography className={classes.description} variant="body2" component="p">
            {text.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>))}
    </>
    : option===0? <People></People>
    : option===1? <Usage></Usage>
    : option===2? 'Logs'
    : option===3? 'APIs'
    :<div></div>}
      </>
  );
}