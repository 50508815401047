import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import style from './style'
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles((theme) => (style));

export default function ChipList(props) {

    const classes = useStyles();
    const [data, setData] =React.useState()

    React.useEffect(() =>  {
            parseData(JSON.parse(props.data).labels)
       }, [props.data]);

    const handleDelete = () =>{

    }  
    
    const handleClick = () =>{

    }  

    const parseData = (chipData) =>{
      if (chipData){
        console.log(chipData)
        var chips = []
        for (var i = 0; i < chipData.length; i ++){
            var chipRow = {
                label: chipData[i].name,
                confidence: chipData[i].metadata.confidence.toFixed(0) + ' %'
            }
            chips.push(chipRow)
        }
        setData(chips)
      }
    }  

    return (
        <>
       {data? 
       <div className = {classes.root}>
       {data.map((data) => {
         return (
           <li key={data.key}>
             <Chip
              // icon={icon}
               label={data.label + ': ' + data.confidence}
               onClick={handleClick}
               //color = 'secondary'
               //variant = 'outlined'
               onDelete={data.label === 'React' ? undefined : handleDelete(data)}
               deleteIcon={<DoneIcon/>}
               className={classes.chip}
             />
           </li>
         );
       })}
      </div>
        :<div></div>}
        </>
    )
}