import { API } from 'aws-amplify';
import { handler } from '../../graphql/queries';

const commands = {
    echo: {
      description: 'Echo a passed string',
      usage: 'echo <string>',
      fn: function () {
        return `${Array.from(arguments).join(' ')}`
      }
    },
    run: {
      description: 'Run lambda function',
      usage: 'run <string>',
      fn: function () {
          console.log(JSON.stringify(arguments))
        var response = runLambda(arguments)
        return response
      }
    },

    list: {
      description: 'List functions',
      usage: 'list <string>',
      fn: function () {
        return 'getJIRA \n getHubspot \n getFacebook'
      }
    }
  }

  async function runLambda(params) {
    try {
      const response = await API.graphql({
        query: handler
      })
      return response.data.handler;
    } catch (err) {
      return err;
    }
  }

  export default commands