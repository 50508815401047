import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#FFF"
    },
    secondary: {
      main: "#299891"
    },
    error: {
      main: "#ED2838"
    },
    warning: {
      main: "#FFC114"
    },
    success: {
      main: "#90B734"
    }
  }
});

export default theme;
