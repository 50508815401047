import {AmplifyAuthenticator, AmplifySignIn, AmplifyRequireNewPassword,AmplifySignOut, AmplifyForgotPassword, AmplifyConfirmSignUp} from '@aws-amplify/ui-react';
import {I18n, Hub, Auth} from "aws-amplify";
import {Translations} from "@aws-amplify/ui-components";
import {AuthState, onAuthUIStateChange} from '@aws-amplify/ui-components';
import AuthUICSS from './AuthUICSS.js'
import React, {useState, useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {setSpanish, setChinese, setEnglish, authConfig} from './AuthUIConf.js'
import Drawer from '../Drawer/index';
import LanguageContext from "../../context/LanguageContext"
import AuthContext from "../../context/AuthContext"
import LoaderContext from "../../context/LoaderContext";
import {
  Backdrop,
  CircularProgress
} from "@material-ui/core"


I18n.putVocabulariesForLanguage("es-ES", setSpanish());
I18n.putVocabulariesForLanguage("en-EN", setEnglish());
I18n.putVocabulariesForLanguage("zh-CN", setChinese());

const useStyles = makeStyles((theme) => (AuthUICSS));
const config = authConfig();

function AuthUI(props) {

  const {loader} = useContext(LoaderContext);
  const classes = useStyles();
  const [authState, setAuthState] = useState();
  const [tab, setTab] = useState(0);
  const {language, setLanguage} = useContext(LanguageContext);
  const {user, setUser} = useContext(AuthContext);
  const [searchField, setSearchField] = useState("");

  I18n.setLanguage(language);
  var heightAuth = window.innerHeight.toString() + 'px';

  const handlerSetTab = (tab) => {
    if (!isNaN(tab)) {
      setTab(tab);
    }
  };

  const handleChangeSearchField = (event) => {
    setSearchField(event.target.value);
  };

  const handleGetIdentityId = async () => {
    return await (await Auth.currentCredentials())
  };

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);

      handleGetIdentityId()
        .then((response) => {
          if (authData) {
            authData.identityId = response.identityId;
            authData.credentials = response
          }
          setUser(authData);
        });
    });
  }, []);

  return (
  <div>
      {
        authState === AuthState.SignedIn && user ? (
          <div className="App">  
           <Drawer  searchField={searchField} setSearchField={handleChangeSearchField}/>
          </div>
        ) : (
          <div className={classes.root}>

            <AmplifyAuthenticator>
            <div className="App" slot="forgot-password" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: heightAuth,
                minHeight: '700px',
                width: '100%',
                backgroundImage: 'linear-gradient( 35deg, rgba(3, 95, 106, 0.2), rgba(0, 20, 31, 0.7)), url(' + config.background + ')',
                backgroundSize: '100% 100%',
                borderRadius: '0px'
              }}>
              <AmplifyForgotPassword  user={user}></AmplifyForgotPassword>
              </div>
              {/* <div className="App" slot="require-new-password" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: heightAuth,
                minHeight: '700px',
                width: '100%',
                backgroundImage: 'linear-gradient( 35deg, rgba(3, 95, 106, 0.2), rgba(0, 20, 31, 0.7)), url(' + config.background + ')',
                backgroundSize: '100% 100%',
                borderRadius: '0px'
              }}>
              <AmplifyRequireNewPassword></AmplifyRequireNewPassword>
              </div> */}
              <div className="App" slot="confirm-sign-up" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: heightAuth,
                minHeight: '700px',
                width: '100%',
                backgroundImage: 'linear-gradient( 35deg, rgba(3, 95, 106, 0.2), rgba(0, 20, 31, 0.7)), url(' + config.background + ')',
                backgroundSize: '100% 100%',
                borderRadius: '0px'
              }}>
              <AmplifyConfirmSignUp></AmplifyConfirmSignUp>
              </div>

              <div className="App" slot="sign-in" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: heightAuth,
                minHeight: '700px',
                width: '100%',
                backgroundImage: 'linear-gradient( 35deg, rgba(3, 95, 106, 0.2), rgba(0, 20, 31, 0.7)), url(' + config.background + ')',
                backgroundSize: '100% 100%',
                borderRadius: '0px'
              }}>
              
                <AmplifySignIn
                  hideSignUp={true}
                  headerText={I18n.get(Translations.SIGN_IN_HEADER_TEXT)}
                  formFields={[
                    {
                      type: "username",
                      label: I18n.get(Translations.USERNAME_LABEL),
                      placeholder: I18n.get(Translations.USERNAME_PLACEHOLDER),
                      required: true,
                    },
                    {
                      type: "password",
                      label: I18n.get(Translations.PASSWORD_LABEL),
                      placeholder: I18n.get(Translations.PASSWORD_PLACEHOLDER),
                      required: true,
                    }
                  ]}
                >
                </AmplifySignIn>
              </div>
            </AmplifyAuthenticator>
          </div>
        )
      }
      {
        loader ?
          <Backdrop style={{zIndex: 2500}} open={true}>
            <>
              <img style={{position: "absolute"}}
                   src={"https://fjracademy-public.s3-eu-west-1.amazonaws.com/LOGO+FJA/IMG+LOGO+FJA/FJA.png"}
                   width={"100px"} height={"100px"}/>
              <CircularProgress style={{color: "#034450", position: "absolute"}} size={120}/>
            </>
          </Backdrop> :
          null
      }
</div>
  );
}

export default AuthUI;
