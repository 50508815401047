const style = {
    root: {
      backgroundColor: '#152939 !important',
      minHeight: '600px !important',
      textAlign: 'start',
      margin: '20px'
    },
    label: {
      color: 'red'
    }
}

export default style