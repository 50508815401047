import { minWidth } from "@material-ui/system"

const style = {
    root: {

      "& .MuiListItem-root":{
        display: 'block'
      },
      "& .MuiAccordion-rounded":{
        width: '100%'
      },
      "& .MuiAccordionDetails-root":{
        display: ''
      },
      "& .MuiTypography-colorPrimary":{
        color: '#299891'
      },
      "& .MuiAccordionSummary-root":{
        minHeight: '0px',
        height: '25px'
      },
      minWidth: '320px',
      marginTop: '20px'
      },
    list: {
      display: 'list-item'
    },
    options:{
      textAlign: 'end',
      marginRight: '15px'
    },
    input: {
      width: '100%',
      marginTop: '10px',
      marginBottom:'10px',
      borderBottom: '1px solid #299891'
    },
    item:{
      //width: '300px'
    },
    itemList:{
      whiteSpace: 'normal'
    },
    iconButton: {
      padding: 10,
    },
    inputContainer:{
      textAlign: 'start',
      marginLeft: '15px',
      marginRight: '15px',
      minWidth: '250px'
    },
    title: {
      fontSize: '1rem',
      fontWeight: 'bold'
    }

}

export default style