import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';
import { Storage } from 'aws-amplify';
import Editor from '../Editor/index'
import Excel from '../Excel/index'
import Image from '../Image/index'
import Fab from '@material-ui/core/Fab';
import {GetAppOutlined} from '@material-ui/icons/';


const useStyles = makeStyles((theme) => (style));

export default function ContentData({file}) {

    const classes = useStyles();
    const [object, setObject] = React.useState('');
    const [key, setKey] = React.useState('');
    const [objectFile, setObjectFile] = React.useState();


    React.useEffect(() => {
        setKey(file.key) 
        getObject(file)
      }, [file]);

    const getObject = async (file) => {

        const down_extensions = 
        ["js", "py", "html", "xls", "xlsx", "csv", "json"]
        var download = down_extensions.includes(file.extension)
       
        Storage.get(file.key, { 
            level: 'protected', 
            download: download,
            cacheControl: download===true?'no-cache':''
        }).then(result =>{

            if (download === true){
                if (file.extension === 'xlsx' || file.extension === 'xls' || file.extension === 'csv'){  
                     setObjectFile(result.Body) // Si es excel leemos el fichero
                     console.log(result.Body)
                 } else { // Si es texto, recuperamos el texto
                     result.Body.text().then(string => { 
                     string = String(string)
                     setObject(string)
                    });
                }
             } else {
                 setObject(result) // Si no es descargable, nos basta con el link
             }  

        })

        
    }

    const handleonDownload =  () => {

        Storage.get(key, { 
            level: 'protected', 
        }).then(result =>{
            window.open(result)
        })

    }

    return (
        <>
        {file.extension === 'jpeg'? <Image data = {object} path = {file.key}/>: 
        file.extension === 'jpg' ?  <Image data = {object} path = {file.key}/>: 
        file.extension === 'png' ?  <Image data = {object} path = {file.key}/>:
        file.extension === 'js'?  <Editor mode="javascript" default = "save" object ={object} path = {file.key}/>:
        file.extension === 'json'?  <Editor mode="json" default = "save" object ={object} path = {file.key}/>:
        file.extension === 'py'?  <Editor mode="python" default = "save" object ={object} path = {file.key}/>: 
        file.extension === 'html'?  <Editor mode="html" default = "save" object ={object} path = {file.key}/>: 
        file.extension === 'xls'?  <Excel data={objectFile} path = {file.key}/>: 
        file.extension === 'xlsx'?  <Excel data={objectFile} path = {file.key}/>: 
        file.extension === 'csv'?  <Excel data={objectFile} path = {file.key}/>: 
        <div></div>}
        <Fab className= {classes.buttonDownload} color="primary" aria-label="add" onClick={handleonDownload}> 
            <GetAppOutlined className= {classes.fabIcon}/>
        </Fab>
        </>
    )
}
