const style = {
    root: {
      marginTop: '20px',
      marginLeft: '20%',
      marginRight: '20%'
      },

      content:{
       // backgroundImage: "url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxqht2scm7dRAsDFTLOykBdUtIEO3I-VhbC4iijXcqhzKHw-hv3Q7FLFNdw-4-fXHtb0E&usqp=CAU')",
        margin: '10px'
      
      },

      title: {
        color: '#299891'
      }

}

export default style