import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => (style));

export default function Message({open, message, type, onClose}) {

  const classes = useStyles();
  const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} variant="outlined" {...props} ref={ref} />);

  const TransitionRight = (props) => {
   return <Slide direction="left" {...props} />;
  }

  return (
    <>
     <Snackbar open={open} autoHideDuration={5000} onClose={onClose}
      anchorOrigin = {{ vertical: 'bottom', horizontal: 'center' }}
      TransitionComponent = {TransitionRight}
     >
     <Alert variant = 'filled' severity={type}>
             {message}
        </Alert>
     </Snackbar>      
    </>
  )
}
