import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Home from '@material-ui/icons/HomeTwoTone'
import Data from '@material-ui/icons/StorageTwoTone'
import Console from '@material-ui/icons/CodeTwoTone'
import Pipelines from '@material-ui/icons/WorkTwoTone'
//import StudioLab from '@material-ui/icons/DesktopMacTwoTone'
import Analytics from '@material-ui/icons/PollTwoTone'
import Reports from '@material-ui/icons/PieChartTwoTone'
import Organization from '@material-ui/icons/BusinessTwoTone'
import ListCSS from './style';

const useStyles = makeStyles((theme) => (ListCSS));

export default function DrawerList({parentCallback}) {

const classes = useStyles();
const [selected, setSelected] = React.useState(0);

const handleSelected = (index) => {
 setSelected(index);
 parentCallback(index)
};

return (

    <div>
        <List>
        {['Home', 'Pipelines', 'Explorer',  'Workspace',
         'Reports','Console', 'My Organization'].map((text, index) => (
            <MenuItem button key={text} 
            classes={{ root: classes.root, selected: classes.selected }}
            onClick={() => handleSelected(index)}
            selected = {selected===index}>
            <ListItemIcon className={selected === index? classes.iconSelected : classes.icon}>
            { index === 0 ? < Home/> : 
              index === 1 ? < Pipelines/> : 
              index === 2 ? < Data/> : 
              index === 3 ? < Analytics/> : 
              index === 4 ? < Reports/> : 
              index === 5 ? < Console/> : 
              index === 6 ? < Organization/> : 
             < Home/>}
            </ListItemIcon>
            <ListItemText primary={text} />
            </MenuItem>
        ))}
        </List>

    </div> 
)
}