import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';
import {List, ListItem, Link, Popover, MenuItem, ListItemText } from '@material-ui/core';

const useStyles = makeStyles((theme) => (style));

export default function PopoverList({anchorEl, onClose, popoverData, onClick}) {

  const classes = useStyles();

  React.useEffect(() =>  {

  }, []);

  var open = Boolean(anchorEl);

  const handleClose = () => {
    onClose()
  };

  const handleOptions = (data) => {
    onClick({option: data})
  };

  return (
    <>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
         <List>
          {popoverData?popoverData.map((item, index) => (
              <MenuItem 
                  className = {classes.itemList} 
                  onClick={() => handleOptions(item.label)}
                  divider={false}>
                  <ListItemText
                      primary= {item.label}
                  />
              </MenuItem>
            )):<div></div>}
        </List>
      </Popover>
              
    </>
  )
}
