

const style = {

    root: {
     "& .layout-splitter:hover":{
        backgroundColor: '#299891'
      },
       "& .splitter-layout":{
        width: 'calc(100% - 55px)',
        height: 'calc(100% - 85px)',
      }, 
      "& .layout-pane":{
          marginRight: '10px',
          marginLeft: '10px'
      }
    },
    file: {

    },
    paper:{
        padding: '20px'
    },
    content:{
        padding: '20px',
        minWidth: '380px'
    },
    splitter: {
        width: '500px'
    },
    buttonLeft:{
        textAlign: 'start',
        height: '50px',
        marginTop:'-20px'
      },
}

export default style