import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Slide from '@material-ui/core/Slide';
import Texts from './texts'
import FileSystem from '../FileSystem/index'
import ColumnList from "../ColumnList/index";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => (style));

export default function DialogSlide(props) {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [texts, setTexts] = React.useState({})
  const [valueRadio, setValueRadio] = React.useState(0)
  const [mode, setMode] = React.useState('')
  const [formData, setFormData] = React.useState({});
  let textInput = React.useRef(null);

  React.useEffect(() => {
    setMode(props.mode)
    setTexts(Texts[props.mode])
    if (props.default){
     setFormData(props.default)
    } 
    setOpen(props.open)
 }, [props.open]);

  const handleClose = () => {
    props.onCancel()  
  };

  const handleChanges = (event) => {
    var formDataNew = formData;
    if (event.target){
    formDataNew[event.target.id?event.target.id:event.target.name?event.target.name:''] = event.target.value
    }
    if (event.relativeKey){
      formDataNew.key = event.relativeKey  
    }

    if (event.level){
      formDataNew.level = event.level  
    }
    setFormData(formDataNew);
  };

  const handleColumnChanges = (columns) => {
    var formDataNew = formData;
    formDataNew.columns = columns;
    setFormData(formDataNew);
  }

  const handleListChanges = (list, id) => {
    var formDataNew = formData;
    formDataNew[id] = list;
    setFormData(formDataNew);
  }

  const handleConfirm = () => {
    props.onConfirm({
      option: texts.options?texts.options[valueRadio].valueParent:'', 
      form: formData
    })
  };

  const handleRadioChange = (event, index) => {
    setValueRadio(parseInt(index))
  };

  return (
    <div className= {classes.root}>
      <Dialog
        id={Math.random().toString(36).substr(2)}
        maxWidth='md'
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{texts?texts.tittle:''}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
           {texts?texts.description:''}
          </DialogContentText>
          {mode.includes('option') && texts.options ?
          <FormControl component="fieldset">
            <FormLabel component="legend"></FormLabel>
              <RadioGroup aria-label="group" name="group1" value={valueRadio} onChange={handleRadioChange}>
                {texts.options.map((item) => (
                <FormControlLabel value={item.value} control={<Radio />} label={item.label} />
                ))}
              </RadioGroup>
          </FormControl>:<div></div>}
           { mode.includes('form') && texts.fields ?
              <div>
               {texts.fields.map((item, index) => (
                  <FormGroup className= {classes.field}>
                    {(item.type === 'input' || item.type==='multiline')?
                    <TextField
                    className ={classes.input}
                    required = {item.mandatory}
                    multiline={item.type==='multiline'}
                    variant="outlined"
                    placeholder=''
                    id={item.id}
                    label={item.disabled?'':item.label}
                    type="text"
                    disabled = {item.disabled}
                    autoFocus={false}
                    fullWidth
                    onChange={handleChanges}
                    value = {formData?formData[item.id]:''}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelInput,
                        focused: classes.labelInputFocused
                      }
                    }}
                  />
                  :item.type === 's3'?
                  <FormControl variant="outlined" className={classes.formControl}>  
                  <InputLabel className= {classes.labelInput} id={"s3" + index}>{item.label}</InputLabel>
                    <Box height = '300px' marginTop = '50px' style={{padding: '10px'}} >
                      <FileSystem dataCallback={handleChanges} columns={true} columnsCallback = {handleColumnChanges}/>
                    </Box>
                  </FormControl>  
                 :item.type === 'emptyList'?
                 <FormControl variant="outlined" className={classes.formControl}>  
                 <InputLabel className= {classes.labelInput} id={"emptyList" + index}>{item.label}</InputLabel>
                   <Box height = '120px' marginTop = '50px'
                    display="flex" flexDirection="column"
                    style={{
                    //border: "1px solid #b0b0b0",
                    borderRadius: '4px',
                    overflow: "hidden",
                    paddingTop: '10px',
                    overflowY: "scroll" 
                    }}>
                       <ColumnList id={item.id} empty={1} callback = {handleListChanges}/>
                   </Box>
                 </FormControl>  
                       :item.type === 'columnList'?
                       <FormControl variant="outlined" className={classes.formControl}>  
                       <InputLabel className= {classes.labelInput} id={"columnList" + index}>{item.label}</InputLabel>
                         <Box height = '120px' marginTop = '50px'
                          display="flex" flexDirection="column">
                            <ColumnList id={item.id} dataProps = {formData?formData.columns:null} callback = {handleListChanges}/>
                         </Box>
                       </FormControl>                   
                  :item.type === 'select'?
                  <FormControl variant="outlined" className={classes.formControl}>  
                  <InputLabel className= {classes.labelInput} id={"label" + index}>{item.label}</InputLabel>
                   <Select 
                     labelId={"label" + index}
                     defaultValue=""
                     value={formData[item.id]}
                     name={item.id}
                     label={item.label}
                     variant="outlined"
                     onChange={handleChanges}
                   >
                     {item.values.map((menuItem) => (
                     <MenuItem value={menuItem.id}>{menuItem.value}</MenuItem>
                     ))}
                   </Select>
                   </FormControl>
                    :<div></div>
                    }
                  </FormGroup>
                ))}
               </div>

          :<div></div>} 
          </DialogContent>
        <DialogActions>
          <Button  className= {classes.agree} onClick={handleConfirm} color="primary">
            {texts?texts.confirm:''}
          </Button>
          <Button  className= {classes.cancel} onClick={handleClose} color="primary">
            {texts?texts.cancel:''}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}