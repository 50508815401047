import React, { Component } from 'react'
import Terminal from 'react-console-emulator'
import {makeStyles} from '@material-ui/core/styles';
import style from './style'
import commands from './commands'


const useStyles = makeStyles((theme) => (style));


export default function Console(props) {

    const classes = useStyles();

    return (
      <Terminal
      className = {classes.root}
      commands={commands}
      styleEchoBack= 'fullInherit'
      promptLabelStyle={{ color: '#299891' }} 
      inputTextStyle={{ color: '#299891' }} 
      />
    )
  
}