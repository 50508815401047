import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';
import Console from '../../components/Console/index'
import ListAccordion from '../../components/ListAccordion/index'
import SplitterLayout from 'react-splitter-layout'
import Info from '@material-ui/icons/Info'
import Back from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton'
import commands from '../../components/Console/commands'

const useStyles = makeStyles((theme) => (style));

export default function ConsolePage() {

    const classes = useStyles();
    const [visibleCommands, setVisibleCommands] = React.useState(false);
    const [commandList, setCommandList] = React.useState();

    React.useEffect(() =>  {
       parseCommands()
    }, [])

    const parseCommands = () => {

      var commandArray = []
      var keys = Object.keys(commands)

      for (var j = 0;j < keys.length ; j++){
        var commandItem = {
          Id: keys[j],
          Name: commands[keys[j]].usage,
          Description: commands[keys[j]].description
        }
        commandArray.push(commandItem)
      }
       setCommandList(commandArray)
    }

  return (
    <div className = {classes.root} >
      <SplitterLayout  secondaryInitialSize = {75} percentage = {true}>
      {visibleCommands?
        <div className = {classes.content2}>
          <ListAccordion title = 'Commands' data={commandList} />
        </div>
       : null} 
        <div className = {classes.content}>
        <div className =  {classes.buttonLeft} >
         <IconButton
             color="secondary" 
             onClick={() => {
               setVisibleCommands(visibleCommands?false:true)
              }
             }>
             {visibleCommands?<Back/>:<Info/>}
         </IconButton>
        </div>
          <Console></Console>
        </div>
      </SplitterLayout>
    </div>
  )
}
