import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';
import ApexChart from 'react-apexcharts'

const useStyles = makeStyles((theme) => (style));

export default function Chart(props) {

  const classes = useStyles();

  return (
    <ApexChart 
        options={props.options} 
        series={props.series} 
        type={props.type} 
        width={props.width} 
        height={props.height}
    />
  )
}
