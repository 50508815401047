const style = {
    root: {

      },
    list:{
      width: '25vh',
      borderRight: '1px solid #F0F0F0'

    },
    grid: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: '8px',
      backgroundColor: 'white',
      marginTop: '10px'
    },
    grid2: {
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'flex-start'
    },
    title: {
      fontWeight: 'bold'
    },
    value:{
      fontSize: '20px'
    },
    dim: {
      fontWeight: 'bold',
      fontSize: '25px'
    },
    item: {
      margin: '20px'
    },
    buttonGroup:{
      textAlign: 'end',
      margin: '15px',
      minWidth: '600px'

    },
    button:{
      marginLeft: '10px',
      border: '1px solid #299891'
    },
    detail:{
      width: '100%',
      backgroundColor: 'white',
      marginLeft: '20px'
    }
}

export default style