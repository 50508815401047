import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';
import Loader from "../Loader/index";
import Message from '../Message/index'
import AuthContext from "../../context/AuthContext";
import { List, ListItemText, MenuItem, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Chart from '../Chart/index'

const useStyles = makeStyles((theme) => (style));

export default function Metadata(props) {

  const classes = useStyles();
  const [showLoader, setShowLoader] = React.useState(false);
  const [showPlot, setShowPlot] = React.useState(false);
  const [showHist, setShowHist] = React.useState(false);
  const [openMessage, setOpenMessage] = React.useState(false); // Message
  const [message, setMessage] = React.useState(''); // Texto message
  const [messageType, setMessageType] = React.useState(''); // Tipo message
  const [objectKeys, setObjectKeys] = React.useState(); 
  const [detail, setDetail] = React.useState(); 
  const [dimension, setDimension] = React.useState(); 
  const [dataChart, setDataChart] = React.useState(); 
  const [dataHist, setDataHist] = React.useState(); 
  const {user} = React.useContext(AuthContext)
  const forceUpdate = React.useReducer(bool => !bool)[1];

  React.useEffect(() =>  {
     if (props.metadata){
       console.log(props.metadata)
      getObjectKeys(props.metadata)
     }
  }, [props.metadata]);

  const onCloseMessage = () => {
    setOpenMessage(false)
  }

  const createMessage = (text, type) => {
    setMessage(text)
    setMessageType(type)
    setOpenMessage(true)
  }

  const handleSelected = (id) => {

    setDimension(id)
    setShowPlot(false)
    setShowHist(true)

    var detailList = []

    //Nulls y totales
    var nulls = 0
    var outliers = 0
    var interquartile = props.metadata[id]["75%"] - props.metadata[id]["25%"] 
    var index=1.5
    var counts = {}
    var m10 = (props.metadata[id]["max"] - props.metadata[id]["min"]) / 10
    var v1=(props.metadata[id]["min"] + m10),
        v2=(props.metadata[id]["min"] + (m10*2)),
        v3=(props.metadata[id]["min"] + (m10*3)),
        v4=(props.metadata[id]["min"] + (m10*4)),
        v5=(props.metadata[id]["min"] + (m10*5)),
        v6=(props.metadata[id]["min"] + (m10*6)),
        v7=(props.metadata[id]["min"] + (m10*7)),
        v8=(props.metadata[id]["min"] + (m10*8)),
        v9=(props.metadata[id]["min"] + (m10*9))

    for (var i = 0; i < props.data.length; i++){

      if (props.data[i][id] === '' || props.data[i][id] === null || props.data[i][id] === undefined){
        nulls++
      }
      if (props.data[i][id] < (props.metadata[id]["25%"] - (index*interquartile)) || props.data[i][id] > (props.metadata[id]["75%"]+(index*interquartile))){
        outliers++
      }

      //Si es una métrica creamos el histograma de 10 valores haciendo variables discretas
      if (!props.metadata[id]["top"]){
        if (props.data[i][id] >= props.metadata[id]["min"] && props.data[i][id] <= v1){
          counts["01|  " + v1] = counts["01|  " + v1]?counts["01|  " + v1] + 1:1
        } else if (props.data[i][id] > v1 && props.data[i][id] <= v2){
          counts["02|  " + v2] = counts["02|  " + v2]?counts["02|  " + v2] + 1:1
        } else if (props.data[i][id] > v2 && props.data[i][id] <= v3){
          counts["03|  " + v3] = counts["03|  " + v3]?counts["03|  " + v3] + 1:1
        } else if (props.data[i][id] > v3 && props.data[i][id] <= v4){
          counts["04|  " + v4] = counts["04|  " + v4]?counts["04|  " + v4] + 1:1
        } else if (props.data[i][id] > v4 && props.data[i][id] <= v5){
          counts["05|  " + v5] = counts["05|  " + v5]?counts["05|  " + v5] + 1:1
        } else if (props.data[i][id] > v5 && props.data[i][id] <= v6){
          counts["06|  " + v6] = counts["06|  " + v6]?counts["06|  " + v6] + 1:1
        } else if (props.data[i][id] > v6 && props.data[i][id] <= v7){
          counts["07|  " + v7] = counts["07|  " + v7]?counts["07|  " + v7] + 1:1
        } else if (props.data[i][id] > v7 && props.data[i][id] <= v8){
          counts["08|  " + v8] = counts["08|  " + v8]?counts["08|  " + v8] + 1:1
        } else if (props.data[i][id] > v8 && props.data[i][id] <= v9){
          counts["09|  " + v9] = counts["09|  " + v9]?counts["09|  " + v9] + 1:1
        } else if (props.data[i][id] > v9 && props.data[i][id] <= props.metadata[id]["max"]){
          counts["10|  " + props.metadata[id]["max"]] = counts["10|  " + props.metadata[id]["max"]]?counts["10|  " + props.metadata[id]["max"]] + 1:1
        } 
      // Si es una dimensión, mostramos hasta 1000 valores  
      } else {
        counts[props.data[i][id]] = counts[props.data[i][id]]?counts[props.data[i][id]] + 1:1 
      }
    }
    counts = Object.keys(counts).sort().reduce(
      (obj, key) => { 
        obj[key] = counts[key]; 
        return obj;
      }, 
      {}
    );

    console.log(counts)
    var xHist = Object.keys(counts)
    var yHist = Object.values(counts)


    if (xHist.length > 1000){ // 1000 son muchos registros para un bar chart
      xHist = []
      yHist = []
      setShowHist(false)
    }

    var  item = {
      name : 'total',
      value : props.data.length
      }
  
    detailList.push(item)

    item = {
      name : 'nulls',
      value : nulls
      }
  
    if (nulls > 0) { 
    detailList.push(item)
    }


    // Lo que sale de Pandas
    var keys = Object.keys(props.metadata[id])
    for (var i = 0; i < keys.length; i++){
      var detailItem = {
          name: keys[i],
          value: props.metadata[id][keys[i]]
      }
      if (detailItem.name === 'mean' || detailItem.name === 'std'){
        detailItem.value = Math.round(detailItem.value * 100) / 100
      }
      if (detailItem.name === 'top'){
        detailItem.name = 'mode'
      }
      if (detailItem.name === 'freq'){
        detailItem.name = 'mode freq'
      }

      if (detailItem.value !==null && detailItem.value !== 0 && detailItem.name !== 'count'){
       detailList.push(detailItem)  
       if (detailItem.name === 'mean'){
         setShowPlot(true)
       }
      }
     
    }
    
    
    item = {
    name : 'interquartile',
    value : interquartile 
    }

    if (item.value !== 0){
    detailList.push(item)
    }

    item = {
      name : 'outliers',
      value : outliers
      }
  
    if (outliers > 0) { 
    detailList.push(item)
    }

    setDetail(detailList)

    //Chart
    const dataPlot = {
      series: [
        {
          type: 'boxPlot',
          data: [
            {
              x: '',
              y: [props.metadata[id]["min"], props.metadata[id]["25%"], props.metadata[id]["mean"], props.metadata[id]["75%"], props.metadata[id]["max"]]
            }
          ]
        }
      ],
      options: {
        chart: {
          toolbar: {
            show: false
          }
        }
      }
    }

        //Chart
        const dataHist = {
          series: [
            {
              data: yHist 
            }
          ],
          options: {
            chart: {
              type: 'bar',
              toolbar: {
                show: false
              }
            },
            xaxis: {
              categories: xHist
            },
          }
        }
    
    setDataChart(dataPlot)
    if (showPlot){
      setShowPlot(true)
    }
    setDataHist(dataHist)
    forceUpdate()
  }

  const getObjectKeys = (data) => {
    var keyList = []
    var keys = Object.keys(data)
    for (var i = 0; i < keys.length; i ++ ){
      var keyItem = {
        id: keys[i]
      }
      keyList.push(keyItem)
    }
    setObjectKeys(keyList)
  }

  const handleNulls = () => {

  }
  const handleOutliers = () => {

  }

  const removeColumn = () => {

  }

  return (
    <>
     <Message open = {openMessage} message = {message} type = {messageType} onClose={onCloseMessage}/>
     <Loader show = {showLoader}/>
     {objectKeys?
     <div className = {classes.grid}>
        <div>
        <List className = {classes.list} dense={true}  disablePadding >
        {objectKeys
            .map((item, index) => (
              <MenuItem 
              className = {classes.itemList} 
              onClick={() => handleSelected(item.id)}
              divider={true}> 
              <ListItemText style={{
                  marginLeft: '5px'
                  }}
                  primary= {item.id}
              />
            </MenuItem>
            ))}
        </List>
        </div>
        {detail?
        <div>
        <div className = {classes.detail}>
         <Typography className = {classes.dim}>{dimension}</Typography>
         <div className = {classes.buttonGroup}>
                <Button  className ={classes.button} color="secondary" onClick={removeColumn}>
                  Remove
                </Button>
                <Button  className ={classes.button} color="secondary" onClick={handleNulls}>
                  Nulls
                </Button>
                <Button  className ={classes.button} color="secondary" onClick={handleOutliers}>
                  Outliers
                </Button>
        </div>
        <div className = {classes.grid2}>
             {detail.map((item, index) => (
               <div>
               <div className = {classes.item}>   
               <Typography className = {classes.title}>{item.name}</Typography>
               <Typography className = {classes.value}>{item.value}</Typography>
               </div>
               </div>
            ))}
        </div>
        {showHist?
        <Chart options={dataHist.options} series={dataHist.series} type="bar" width= {'100%'} height={400} />
        :null}
{/*         {showPlot?
        <Chart options={dataChart.options} series={dataChart.series} type="boxPlot" width= {'100%'} height={400} />
        :null} */}
        </div></div>:null}
     </div>
     :null}      
    </>
  )
}
