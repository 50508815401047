const style = {
    root: {
       padding: '10px',
       "& .MuiPaper-root":{
         padding: '10px'
       },
       height: '900px'
      },
      buttonAdd:{
        bottom: 30,
        position: 'fixed',
        right: 30
      },
      table: {
        paddingRight: '20px',
        paddingLeft: '10px'
      },
      paperGauge:{
        marginBottom: '20px',
        marginRight: '20px',
        marginLeft: '10px',
        textAlign: 'start'
      },
      paperBar:{
        marginBottom: '20px',
        marginRight: '20px',
        marginLeft: '20px'
      },
}

export default style