import React, {useState} from "react";

const Context = React.createContext({});

export function LoaderProvider ({children}) {
  const [loader, setLoader] = useState(false);

  return <Context.Provider value={{loader, setLoader}}>
    {children}
  </Context.Provider>
}

export default Context;
