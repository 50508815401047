import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import style from './style'
import Fab from '@material-ui/core/Fab';
import {ImageSearch} from '@material-ui/icons/';
import Predictions from '@aws-amplify/predictions';
import Grid from '@material-ui/core/Grid';
import Editor from '../Editor/index'
import Loader from "../Loader/index";
import DialogSlide from '../Dialog/index'
import ChipList from '../ChipList/index'
import { useMediaQuery } from 'react-responsive'
import SplitterLayout from 'react-splitter-layout'
import { API } from "aws-amplify";
import AuthContext from "../../context/AuthContext";

const useStyles = style

export default function Image(props) {

    const classes = useStyles();
    const [data, setData] =React.useState()
    const [key, setKey] =React.useState()
    const [scan, setScan] =React.useState()
    const [scanKey, setScanKey] =React.useState()
    const [showLoader, setShowLoader] = React.useState(false);
    const [openDialog, setOpenDialog] =React.useState(false)
    const {user} = React.useContext(AuthContext)

    React.useEffect(() =>  {
            setScan()
            setData(props.data)
            setKey(props.path)
       }, [props.data]);

       const isMobile = useMediaQuery({
        query: '(max-device-width: 900px)'
      })


    const handleScan = async (option) => {
        setShowLoader(true)
        Predictions.identify({
            labels: {
              source: {
                key: key,
                level: 'protected', 
              },
              type: option
            }
          })
          .then(response => {
            setScan(JSON.stringify(response, null, "\t"))
            var newScanKey = newScanKey = key.substr(0, key.lastIndexOf(".")) + ".json";
            newScanKey = newScanKey.substr(0, newScanKey.lastIndexOf("/")) + "/Labels" + newScanKey.substr(newScanKey.lastIndexOf("/"),newScanKey.length ) ;
            console.log(newScanKey)
            setScanKey(newScanKey)
            setShowLoader(false)
        })
        .catch(err => console.log({ err }));
    }  

    const handleOption = (response) => {
        if (response.option.includes("arn", 0)){
          describeImage(response.option)
        } else {
        handleScan(response.option)
        }
        setOpenDialog(false)
    }

    const describeImage = async (arn) => {

      var image = `{user: "${user.identityId}" , key: "${key}" , model: "${arn}"}`
      const response = await API.graphql({
        query: `query DescribeImage { describeImage(data:${image}) }` 
      })

      const dataAux = JSON.parse(response.data.describeImage)
      setScan(JSON.stringify(dataAux, null, "\t"))
      var newScanKey = newScanKey = key.substr(0, key.lastIndexOf(".")) + ".json";
      newScanKey = newScanKey.substr(0, newScanKey.lastIndexOf("/")) + "/Labels" + newScanKey.substr(newScanKey.lastIndexOf("/"),newScanKey.length ) ;
      setScanKey(newScanKey)
      setShowLoader(false)
    }

    const handleOpenDialog = () => {      
    setOpenDialog(true)
    }

    const handleCloseDialog = () => {      
      setOpenDialog(false)
      }

    return (
        <>
          <SplitterLayout vertical={true} secondaryInitialSize = {65} percentage = {true}>
            <div>
            <img src = {data} height='650px'></img>
            </div>
            {scan? 
            <div>
              <div >
              <ChipList  data = {scan}/>
              </div>
              <Editor mode="json" object ={scan} path = {scanKey} default='save'/>

            </div>:null}
          </SplitterLayout>
              
        <Loader show = {showLoader}/>

        <DialogSlide 
          open = {openDialog} 
          mode = 'optionScan' 
          onConfirm = {handleOption}
          onCancel = {handleCloseDialog}
        />

         {!scan? 
        <Fab className= {classes.buttonFab} color="primary" aria-label="add" onClick={handleOpenDialog}> 
            <ImageSearch className= {classes.fabIcon}/>
        </Fab>: <div/>} 
        </>
    )
}