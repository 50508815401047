const style = {
    root: {

    },
    iframe: {
    border: 0,
    height: '90vh'
    } 
}

export default style