const style = {
    root: {
    },
    buttonFile:{
      bottom: 50,
      position: 'fixed',
 //     left: '40px !important'
    },
    fabIcon: {
        fontSize:'2.2rem'
    },
    icon:{
      fill: '#000',
      fontSize: '1.6rem',
      marginRight: '12px',
      verticalAlign: 'middle' 
    },
    buttonGroup:{
      textAlign: 'end'
    },
    button:{
      marginLeft: '10px',
      border: '1px solid grey',
      marginBottom: '10px',
      color: 'grey'
    },
    bread: {
      marginBottom: '15px',
      marginLeft: '5px'
    },    
}

export default style