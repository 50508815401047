import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';
import Loader from "../../components/Loader/index";
import { API } from "aws-amplify";
import AuthContext from "../../context/AuthContext";
import Message from '../../components/Message/index'
import Iframe from 'react-iframe'

const useStyles = makeStyles((theme) => (style));

export default function Quicksight(props) {

  const classes = useStyles();
  const [showLoader, setShowLoader] = React.useState(true);
  const [dashboard, setDashboard] = React.useState(false);
  const {user} = React.useContext(AuthContext)
  const [openMessage, setOpenMessage] = React.useState(false); // Message
  const [message, setMessage] = React.useState(''); // Texto message
  const [messageType, setMessageType] = React.useState(''); // Tipo message

  React.useEffect(() =>  {
     getDashobard()
  }, []);

  const getDashobard = async () =>{
    setTimeout(function(){ setShowLoader(false) }, 2500);
    var params =  `{user: "${user.identityId}"}`
    const response = await API.graphql({
      query:  `query GetQuicksight {
        getQuicksight(quicksight: ${params})
      }`
    })

    const dataAux = JSON.parse(response.data.getQuicksight)
    if (dataAux.status && dataAux.status !== 'success'){
      createMessage(JSON.stringify(dataAux.message),dataAux.status)
    } else {
      setDashboard(dataAux.EmbedUrl)
    }
    
    
  }

  const onCloseMessage = () => {
    setOpenMessage(false)
  }

  const createMessage = (text, type) => {
    setMessage(text)
    setMessageType(type)
    setOpenMessage(true)
  }

  return (
    <>
     <Message open = {openMessage} message = {message} type = {messageType} onClose={onCloseMessage}/>
     <Loader show = {showLoader}/>
     <Iframe 
      url={dashboard} 
      width="100%" 
      id="myId" 
      className= {classes.iframe} 
      display="initial" 
      position="relative"/>   
    </>
  )
}
