/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:561e3023-1c86-47f0-a8b0-37af979b0207",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_F9FR1nhRt",
    "aws_user_pools_web_client_id": "o7u7t2rhekolksakaouj1rrr7",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://75limn2lizf3hlwn4zljo6tlo4.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "abradatabra-content115845-main",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "predictions": {
        "identify": {
            "identifyLabels": {
                "proxy": false,
                "region": "eu-west-1",
                "defaults": {
                    "type": "LABELS"
                }
            }
        }
    }
};


export default awsmobile;
