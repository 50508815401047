import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme) => ({

  root: {
    padding: '20px'
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  buttonGroup:{
      textAlign: 'end',
      marginTop: '2px',
      height: '45px'
  },
  button:{
    marginLeft: '10px',
    border: '1px solid grey',
    marginBottom: '5px',
    color: 'grey'
  },
  paper:{
    padding: '20px'
  }
}));
