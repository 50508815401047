const ListCSS = {
    root: {
        '&$selected': {
          backgroundColor: '#299891',
          color: '#FFF',
          '&:hover': {
          color: '#000'
          }
        },
    },
    selected: {},
    icon: {
        color: '#000'
    },
    iconSelected: {
       color: '#FFF'
    }
}

export default ListCSS