
const popoverList = [
    
      {
          id: 0,
          label: 'Preview'
      },
      {
          id: 1,
          label: 'Edit'
      },
      {
          id: 2,
          label: 'Delete'
      }
  ]



  module.exports = {
      popoverList
  }

