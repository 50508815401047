const style = {
    root: {
      padding: '10px',
      },
      paper: {
        padding: '10px',
        margin: '20px'
      }
}

export default style