const style = {
    root: {
      "& .ace_content" :{
        transform: 'none !important'
      },
      "& .MuiPaper-root":{
        padding: '10px',
        marginTop: '10px',
        border: '1px solid #ccc'
      },
      "& .layout-splitter:hover":{
        backgroundColor: '#299891'
      },
      "& .splitter-layout":{
        width: 'calc(100% - 50px)',
        height: 'calc(100% - 50px)',
      }
    },
    content: {
      paddingLeft: '15px',
      paddingRight: '15px',
      paddingBottom: '30px'
      //paddingTop: '8px'
    } , 
    editor: {
      marginBottom: '20px',
    } ,
    editorText:{
      width: '100% !important'
  },
    typography: {
      fontSize: '20px',
      textAlign: 'start',
      marginBottom: '0px'
      },
      typographyL: {
        fontSize: '16px',
        textAlign: 'start',
        marginBottom: '0px',
        marginLeft: '10px',
        fontWeight: 'bold'
        },
    typography2: {
      fontSize: '20px',
      textAlign: 'start',
      marginBottom: '15px'
      },
      typographyT: {
        fontSize: '18px',
        textAlign: 'start',
        marginBottom: '10px',
        marginTop: '20px',
        color: '#299891',
        fontWeight: 'bold'
        }, 

    buttonGroup:{
      textAlign: 'end',
      marginTop: '2px',
      height: '45px'
    },
    buttonGroupLeft:{
      textAlign: 'start',
      marginTop: '2px',
      height: '45px',
      display: 'flex'
    },
    grid: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '8px'
    },
    buttonLeft:{
      textAlign: 'start',
      height: '35px',
      marginBottom: '15px',
       display: 'flex',
    justifyContent: 'space-between'

    },
    button:{
      marginLeft: '10px',
      border: '1px solid grey',
      marginBottom: '5px',
      color: 'grey'
    },
    buttonPrimary:{
      marginLeft: '10px',
      border: '1px solid grey',
      backgroundColor: 'grey',
      color: 'white',
      marginBottom: '5px',
    },
    paper: {
      padding: '10px',
      marginTop: '15px'
    },
    switch: {
      marginLeft: '20px'
    },
    divider: {
      marginTop: '25px'
    }    
}

export default style