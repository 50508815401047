import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import style from './style'
import XLSX from 'xlsx'
import Loader from "../Loader/index";
import Table from "../Table/index";
import { API } from "aws-amplify";
import AuthContext from "../../context/AuthContext";
import SplitterLayout from 'react-splitter-layout'
import Metadata from '../Metadata/index'
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => (style));

export default function Excel(props) {

    const classes = useStyles();
    const [data, setData] =React.useState()
    const [metadata, setMetadata] =React.useState()
    const [columns, setColumns] =React.useState()
    const [showLoader, setShowLoader] = React.useState(false);
    const {user} = React.useContext(AuthContext)

    React.useEffect(() =>  {
         if (props.data){
            setShowLoader(true) 
            readExcel(props.data)
            describeData()
         }
       }, [props.data]);

       const readExcel = (data) => {
        var oFile = data;
        var reader = new FileReader();

        reader.onload = function(e) {
            var data = e.target.result;
            var cfb = XLSX.read(data, {type: 'binary', sheetRows: 50000});
            //var wb = XLSX.parse_xlscfb(cfb);
            cfb.SheetNames.forEach(function(sheetName) {
                //var sCSV = XLSX.utils.make_csv(wb.Sheets[sheetName]);   
                var oJS = XLSX.utils.sheet_to_row_object_array(cfb.Sheets[sheetName]); 
                getColumns(oJS)
            });
        };

        reader.readAsBinaryString(oFile);
    }  

   const getColumns = (dataExcel) => {

        var columnsAux = []
        var keys = Object.keys(dataExcel[0])
        for (var j = 0;j < keys.length; j++){
            var key = keys[j]
            var column = {
                    title: key,
                    field:key
                } 
            columnsAux.push(column)
        }
        setColumns(columnsAux)
        setData(dataExcel)
        //setShowLoader(false) 
    };

    const describeData = async () => {

        var data = `{user: "${user.identityId}" , key: "${props.path}" }`
        const response = await API.graphql({
          query: `query DescribeData { describeData(data:${data}) }` 
        })

        const dataAux = JSON.parse(response.data.describeData)
        var describe = JSON.parse(dataAux.body.describe)
        /*  var mode = JSON.parse(dataAux.body.mode)
        var describeKeys = Object.keys(describe)
        for (var i = 0; i < describeKeys.length; i ++){
            describe[describeKeys[i]].mode = mode[describeKeys[i]][0]
        } */
        
        setMetadata(describe)
        setShowLoader(false) 
    }

    return (
        <>
        <Loader show = {showLoader}/>
       {data? 
       <div className= {classes.root}>
         
        <SplitterLayout vertical={true} percentage = {true} secondaryInitialSize= {50}>  
            <div>
                <Table 
                    edit = {false} //es editable
                    delete = {false} // se puede borrar
                    columns = {columns} // config columnas
                    size = {10}
                    export={false}
                    data = {data} // datos
                >
                </Table>
            </div>
            <div>
                    <Metadata 
                        metadata={metadata}
                        data = {data}>
                    </Metadata>
            </div>
        </SplitterLayout>
         
       </div>:null} 
        </>
    )
}