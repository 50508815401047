const Texts = {
    
    save: {
        tittle: 'Do you want to save the changes?',
        description: 'Some changes are pending to save',
        confirm: 'Agree',
        cancel: 'Cancel'
    },
    optionScan: {
        tittle: 'What do you want to scan?',
        description: 'Select one option',
        confirm: 'Agree',
        cancel: 'Cancel',
        legend: 'Options',
        options: [{
            value: 0,
            label: 'Labels',
            valueParent: 'LABELS'
        },{
            value: 1,
            label: 'Offensive content',
            valueParent: 'UNSAFE'
        },{
            value: 2,
            label: 'Custom: Plant seedlings classification',
            valueParent: 'arn:aws:rekognition:eu-west-1:399139107301:project/plant_seedlings_classification/version/plant_seedlings_classification.2021-12-01T09.34.51/1638347692308'
        }
        ]
    },
    formEditUser:{
        tittle: 'Edit user',
        description: '',
        confirm: 'Edit',
        cancel: 'Cancel',
        fields: [{
            id: 'id',
            label: 'User',
            type: 'input',
            mandatory: true,
            disabled: true,
            values: []
        }, 
        {
            id: 'email',
            label: 'Email',
            type: 'input',
            mandatory: true,
            disabled: true,
            values: [] 
        },  
        {
            id: 'roles',
            label: 'Role',
            type: 'select',
            mandatory: true,                
            disabled: false,
            values: [
                {
                id:"Admins",
                value: "Admins"}, 
                {
                id:"Authors",
                value: "Authors"
                },
                {
                id:"Readers",
                value: "Readers"
                }
            ] 
        }
        ]  
    },
    formCreateUser:{
        tittle: 'Create user',
        description: '',
        confirm: 'Create',
        cancel: 'Cancel',
        fields: [{
            id: 'id',
            label: 'User',
            type: 'input',
            mandatory: true,
            disabled: false,
            values: []
        }, 
        {
            id: 'email',
            label: 'Email',
            type: 'input',
            mandatory: true,
            disabled: false,
            values: [] 
        },  
        {
            id: 'roles',
            label: 'Role',
            type: 'select',
            mandatory: true,                
            disabled: false,
            values: [
                {
                id:"Admins",
                value: "Admins"}, 
                {
                id:"Authors",
                value: "Authors"
                },
                {
                id:"Readers",
                value: "Readers"
                }
            ] 
        }
        ]
    },
    formEditUser:{
        tittle: 'Edit user',
        description: '',
        confirm: 'Edit',
        cancel: 'Cancel',
        fields: [{
            id: 'id',
            label: 'User',
            type: 'input',
            mandatory: true,
            disabled: true,
            values: []
        }, 
        {
            id: 'email',
            label: 'Email',
            type: 'input',
            mandatory: true,
            disabled: true,
            values: [] 
        },  
        {
            id: 'roles',
            label: 'Role',
            type: 'select',
            mandatory: true,                
            disabled: false,
            values: [
                {
                id:"Admins",
                value: "Admins"
                }, 
                {
                id:"Authors",
                value: "Authors"
                },
                {
                id:"Readers",
                value: "Readers"
                }
            ] 
        }
        ]
    },
    formCreateTable:{
        tittle: 'Create table',
        description: '',
        confirm: 'Create',
        cancel: 'Cancel',
        fields: [{
            id: 'id',
            label: 'Table',
            type: 'input',
            mandatory: true,
            disabled: false,
            values: []
        }, 
        {
            id: 'description',
            label: 'Table comment',
            type: 'multiline',
            mandatory: true,
            disabled: false,
            values: [] 
        },  
        {
            id: 'type',
            label: 'File Type',
            type: 'select',
            mandatory: true,
            disabled: false,
            values: [
                   {
                    id:undefined,
                    value: "None"
                    }, 
                    {
                    id:"csv",
                    value: "CSV"
                    }, 
                    {
                    id:"json",
                    value: "JSON"
                    }
            ] 
        },  
        {
            id: 'delimeter',
            label: 'Delimeter',
            type: 'select',
            mandatory: true,
            disabled: false,
            values: [
                {
                    id:undefined,
                    value: "None"
                    },
                    { 
                    id:";",
                    value: ";"
                    }, 
                    {
                    id:",",
                    value: ","
                    }, 
                    {
                    id:"space",
                    value: "SPACE"
                    }
            ] 
        },
        {
            id: 'partition',
            label: 'Partition',
            type: 'emptyList',
            mandatory: true,                
            disabled: false,
            values: []
        },  
        {
            id: 'key',
            label: 'Data source',
            type: 's3',
            mandatory: true,                
            disabled: false,
            values: []
        }
        ]
    },
    formSaveReport:{
        tittle: 'Save report',
        description: '',
        confirm: 'Save',
        cancel: 'Cancel',
        fields: [{
                id: 'report',
                label: 'Name',
                type: 'input',
                mandatory: true,
                disabled: false,
                values: []
            },
            {
                id: 'description',
                label: 'Description',
                type: 'multiline',
                mandatory: true,
                disabled: false,
                values: []
            },
            {
                id: 'type',
                label: 'Type',
                type: 'select',
                mandatory: true,
                disabled: false,
                values: [
                    {
                        id:"online",
                        value: "Online"
                        }, 
                        {
                        id:"offline",
                        value: "Offline"
                        }
                ] 
            },
            ,
            {
                id: 'chart',
                label: 'Chart',
                type: 'select',
                mandatory: true,
                disabled: false,
                values: [
                        {
                        id:"line",
                        value: "Line"
                        }, 
                        {
                        id:"pie",
                        value: "Pie"
                        },
                        {
                        id:"bar",
                        value: "Bar"
                        }, 
                        {
                        id:"area",
                        value: "Area"
                        }, 
                        {
                        id:"radar",
                        value: "Radar"
                        }
                ] 
            }  
        ]
    },
    formCreateDataSet:{
        tittle: 'Create dataset',
        description: '',
        confirm: 'Create',
        cancel: 'Cancel',
        fields: [
        {
            id: 'name',
            label: 'Name',
            type: 'input',
            mandatory: true,
            disabled: false,
            values: []
        }, 
        {
            id: 'description',
            label: 'Description',
            type: 'multiline',
            mandatory: true,
            disabled: false,
            values: []
        }, 
        
        {
            id: 'type',
            label: 'Storage type',
            type: 'select',
            mandatory: true,
            disabled: false,
            values: [
                {
                    id:"NSQL",
                    value: "DATA LAKE (Machine Learning & Exploration)"
                },
                {
                    id:"SQL",
                    value: "DATA WAREHOUSE (Reporting & SQL Machine Learning)"
                } 
           
            ]
        }, 
        {
            id: 'format',
            label: 'Format',
            type: 'select',
            mandatory: true,
            disabled: false,
            values: [
                {
                    id:"csv",
                    value: "CSV"
                },
                {
                    id:"json",
                    value: "JSON"
                } ,
                {
                    id:"parquet",
                    value: "PARQUET"
                } ,
                ,
                {
                    id:"SQL",
                    value: "SQL"
                } 
           
            ]
        }, 
        {
            id: 'schedule',
            label: 'Data refresh',
            type: 'select',
            mandatory: true,
            disabled: false,
            values: [
                {
                    id: "none",
                    value: "None"
                    }, 
                    {
                    id:"hourly",
                    value: "Hourly"
                    },
                    {
                    id:"daily",
                    value: "Daily"
                    },
                    {
                    id:"dsupdate",
                    value: "Datasouce update"
                    }
            ]
        },
        {
            id: 'partitions',
            label: 'Partitions',
            type: 'emptyList',
            mandatory: true,                
            disabled: false,
            values: []
        },
        ,
        {
            id: 'columns',
            label: 'Columns',
            type: 'columnList',
            mandatory: true,                
            disabled: false,
            values: []
        }         
        ]
    }, 
    formCreateQuery:{
        tittle: 'Create query',
        description: '',
        confirm: 'Create',
        cancel: 'Cancel',
        fields: [
        {
            id: 'id',
            label: 'Name',
            type: 'input',
            mandatory: true,
            disabled: false,
            values: []
        }, 
        {
            id: 'description',
            label: 'Description',
            type: 'multiline',
            mandatory: true,
            disabled: false,
            values: []
        }
        ]
    }
}

export default Texts
