const style = {
    root: {
        "& .MuiPaper-root":{
            padding: '10px'
          },
        "& .splitter-layout" :{
            width: '90% important'
        } 
    },
    paper:{
    },
    box:{
        marginTop: '15px'
    }
}

export default style