import React from "react"; 
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Backdrop from '@material-ui/core/Backdrop';
import LoaderCSS from './style'

const MyLoader = (props) => {

    const classes = LoaderCSS();
    const [showLoader, setShowLoader] = React.useState(false);

    React.useEffect(() => {
        setShowLoader(props.show)
      }, [props.show]);

    return (
        <>
        <Backdrop className={classes.backdrop} open={showLoader} >
            <Loader className= {classes.root}
                visible = {showLoader}
                type="Grid"
                color="#fff"
            />
        </Backdrop>
        </>



    )

}

export default MyLoader