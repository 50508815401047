import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';
import FileSystem from '../../components/FileSystem/index'
import ContentData from '../../components/ContentData/index'
import {Paper} from '@material-ui/core';
import SplitterLayout from 'react-splitter-layout'
import 'react-splitter-layout/lib/index.css'
import Back from '@material-ui/icons/ArrowBackIos';
import Forward from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton'
import {Divider} from '@material-ui/core';

const useStyles = makeStyles((theme) => (style));

export default function Data() {

    const classes = useStyles();
    const [key, setKey] = React.useState()
    const [visibleData, setVisibleData] = React.useState(true);
    const [sizeInitial, setSizeInitial] = React.useState(70);
    const [showFile, setShowFile] = React.useState(false);

    const handleCallbackFile = (childData) => {
        setKey(childData)
        setShowFile(true)
    };

    return (
        <div className = {classes.root}>
        <SplitterLayout  percentage = {true} secondaryInitialSize= {sizeInitial} >
         {visibleData?
         <div className = {classes.content}>
            <Paper  className={classes.paper} elevation={4}> 
              <FileSystem dataCallback={handleCallbackFile} editable={true}/>
            </Paper>  
        </div>:null}
        {showFile?
        <div className = {classes.content}> 
            <div className =  {classes.buttonLeft} >
                <IconButton
                    color="secondary" 
                    onClick={(e) => {
                        e.preventDefault()
                        setVisibleData(visibleData?false:true)
                    }
                    }>
                    {visibleData?<Back/>:<Forward/>}
                </IconButton>
                <Divider/>
            </div>  
             {key?
              <div className = {classes.grid}>
             <ContentData file = {key}/> 
             </div>:null}
        </div>:null}
        </SplitterLayout>  
        </div>
    )
}
