import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import chartOptions from './Chart';
import React from 'react';
import Fab from '@material-ui/core/Fab';
import {PersonAddOutlined} from '@material-ui/icons/';
import { API } from "aws-amplify";
import Loader from "../../components/Loader/index";
import Table from "../../components/Table/index"
import DialogSlide from '../../components/Dialog/index'
import Chart from '../../components/Chart/index'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Message from '../../components/Message/index'


const useStyles = makeStyles((theme) => (style));

var columns = [
 // {title: "", width: "60px", render: rowData => <Avatar maxInitials={1} size={35} round={true} src={rowData === undefined ? "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/User_font_awesome.svg/1200px-User_font_awesome.svg.png" :rowData['custom:image']} name={rowData === undefined ? " " : rowData.Username}   /> },
  {title: "Group", field: "Group", defaultSort: 'asc', defaultGroupOrder: 0 },
  {title: "User",  field: "Username", defaultSort: 'asc'},
  {title: "Mail", field: "email"},
  {title: "Status", field: "UserStatus"},
  {title: "Modified", field: "UserLastModifiedDate", type: 'datetime'},
  {title: "Id", field: "sub"}
]

export default function People() {

    const classes = useStyles();
    const [data, setData] =React.useState()
    const [editData, setEditData] =React.useState()
    const [showLoader, setShowLoader] = React.useState(false);
    const [series, setSeries] =React.useState()
    const [seriesBar, setSeriesBar] =React.useState([])
    const [openDialogNew, setOpenDialogNew] =React.useState(false)
    const [openDialogEdit, setOpenDialogEdit] =React.useState(false)
    const [openMessage, setOpenMessage] = React.useState(false); // Message
    const [message, setMessage] = React.useState(''); // Texto message
    const [messageType, setMessageType] = React.useState(''); // Tipo message

    React.useEffect(() =>  {
      listUsers()
    }, []);

    const listUsers = async () => {
      setShowLoader(true)
      const response = await API.graphql({
        query:  `query ListUsers { listUsers }`
      })
      var data = JSON.parse(response.data.listUsers)
      setKPI(data.kpi)
      setData(data.list)
      setShowLoader(false)
    }

    const signUp = async (data) => {
      setOpenDialogNew(false)
      setShowLoader(true)
      var id = data.form.id
      var email = data.form.email
      var newUser = `{email: "${email}", id: "${id}", roles: ` + data.form.roles + `}`
      const response = await API.graphql({
        query:  `query CreateNewUser {
          createNewUser(user: ${newUser})
        }`
      })
      listUsers()
    }

    const deleteUser = async (data) => {
      setShowLoader(true)
      var deleteUser = `{id: "${data.Username}", sub: "${data.sub}" }`
      const response = await API.graphql({
        query:  `query DeleteUser {
          deleteUser(user: ${deleteUser})
        }`
      })
      const dataAux = JSON.parse(response.data.deleteUser)
      if (dataAux.status && dataAux.status !== 'success'){
        setShowLoader(false)
        createMessage(JSON.stringify(dataAux.message),dataAux.status)
      } else {
        listUsers()
      }
    }

    const editUser = async (data) => {

    }

    const createNewUser = async () => {
      setOpenDialogNew(true)
    }

    const closeDialog = async () => {
      setOpenDialogNew(false)
      setOpenDialogEdit(false)
    }

    const handleActions = async (data) => {
      if (data.type === 'delete'){
       deleteUser(data.data)
      }
      if (data.type === 'edit'){
        var editDataAux = {
          id:data.data.Username,
          email:data.data.email,
          roles:data.data.Group
        }
        setEditData(editDataAux)
        setOpenDialogEdit(true)
      }
    }

    const setKPI = (data) => {

      var Admins
      var Totals
      var Authors
      var Readers

      for (var i = 0; i < data.length; i++){
        if (data[i].group === "Admins"){
         Admins = data[i].value
        }
        else if (data[i].group === "Authors"){
          Authors =  data[i].value
        }
        else if (data[i].group === "Totals"){
          Totals  = data[i].value
        }
        else if (data[i].group === "Readers"){
          Readers = data[i].value
        }
      }
        var serieRadar = [
          100,
          (Admins===0?0:(Admins/Totals) * 100).toFixed(1),
          (Authors===0?0:(Authors/Totals) * 100).toFixed(1),
          (Readers===0?0:(Readers/Totals) * 100).toFixed(1)
        ]

        setSeries(serieRadar)
        setSeriesBar([{
          data: [Totals,Admins,Authors,Readers]
         }])

    }

    const createMessage = (text, type) => {
      setMessage(text)
      setMessageType(type)
      setOpenMessage(true)
    }

    const onCloseMessage = () => {
      setOpenMessage(false)
    }
    
  return (
    <div className={classes.root}>
      <Loader show = {showLoader}/>
      <Message open = {openMessage} message = {message} type = {messageType} onClose={onCloseMessage}/>
      {series? 
      <div>
        <Grid container spacing={1}>
        <Grid  item xs={6}>
         <Paper className={classes.paperGauge}>
        <Chart options={chartOptions.gauge} series={series} type="radialBar" height={220} margin = {20} />
        </Paper> 
        </Grid>
        <Grid  item xs={6}>
        <Paper className={classes.paperBar}> 
        <Chart options={chartOptions.bar} series={seriesBar} type="bar" height={184}margin = {30} />
        </Paper> 
        </Grid>
        </Grid>
      </div>
      :<div></div>
      }
      {data?
      <Grid container className={classes.table} spacing={1}>
      <Grid item xs={12}>
      <Table 
        edit = {true} //es editable
        delete = {true} // se puede borrar
        columns = {columns} // config columnas
        size = {5}
        data = {data} // datos
        onActionClick={handleActions} // handle acciones: devuelve type (edit o delete) y los datos de la fila
        >
      </Table>
      </Grid>
       </Grid>
      :<div></div>
      }
      <Fab className= {classes.buttonAdd} color="primary" aria-label="add" onClick={createNewUser}> 
          <PersonAddOutlined className= {classes.fabIcon}/>
      </Fab>
      <DialogSlide 
          open = {openDialogNew} 
          mode = 'formCreateUser' 
          onConfirm = {signUp}
          onCancel = {closeDialog}
        />
      <DialogSlide 
          open = {openDialogEdit} 
          mode = 'formEditUser' 
          onConfirm = {editUser}
          onCancel = {closeDialog}
          default = {editData}
        />
    </div>
  )
}
