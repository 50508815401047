import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';
import Loader from "../../components/Loader/index";
import {Typography, Button, Grid, Switch, Divider, Link} from '@material-ui/core';
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/theme-solarized_light";
import AceEditor from "react-ace";
import { Storage } from 'aws-amplify';
import XLSX from 'xlsx'
import Message from '../../components/Message/index'
import Flexmonster from '../../components/Flexmonster/index'
import ListAccordion from '../../components/ListAccordion/index'
import SplitterLayout from 'react-splitter-layout'
import 'react-splitter-layout/lib/index.css'
import DialogSlide from '../../components/Dialog/index'
import { API } from "aws-amplify";
import AuthContext from "../../context/AuthContext";
import ClearIcon from '@material-ui/icons/Clear'
import AspectRatioIcon from '@material-ui/icons/AspectRatio'
import IconButton from '@material-ui/core/IconButton'
import Back from '@material-ui/icons/ArrowBackIos';
import Forward from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles((theme) => (style));

export default function Analytics() {

  const classes = useStyles();
  const [showLoader, setShowLoader] = React.useState(false);
  const [visibleQuery, setVisibleQuery] = React.useState(true);
  const [visibleReport, setVisibleReport] = React.useState(true);
  const [visibleCatalog, setVisibleCatalog] = React.useState(true);
  const [openDialogNewTable, setOpenDialogNewTable] =React.useState(false)
  const [openDialogNewQuery, setOpenDialogNewQuery] =React.useState(false)
  const [openDialogNewDataSet, setOpenDialogNewDataSet] =React.useState(false)
  const [query, setQuery] =React.useState('')
  const [tables, setTables] = React.useState()
  const [dataSets, setDataSets] = React.useState()
  const [dataSetsDW, setDataSetsDW] = React.useState()
  const [reportList, setReportList] = React.useState()
  const [reportName, setReportName] = React.useState()
  const [queryList, setQueryList] = React.useState()
  const [queryDWList, setQueryDWList] = React.useState()
  const [dataQuery, setDataQuery] = React.useState()
  const [columns, setColumns] = React.useState()
  const [checkSwitch, setCheckSwitch] = React.useState(true)
  const [openMessage, setOpenMessage] = React.useState(false); // Message
  const [message, setMessage] = React.useState(''); // Texto message
  const [messageType, setMessageType] = React.useState(''); // Tipo message
  const forceUpdate = React.useReducer(bool => !bool)[1];
  const {user} = React.useContext(AuthContext)

  React.useEffect(() =>  {
    setCheckSwitch(false)
    setShowLoader(true)
    listTables()
    listQueries()
    listTablesDW() 
    listReports()
  }, []);

  const dialogCreateTable = () => {
    setOpenDialogNewTable(true)
  }

  const runQuery = async (queryParam, typeParam) => {
    
    setShowLoader(true)
    setDataQuery([])
    setReportName('')

    var userId = user.identityId
    var typeSwitch
    if(checkSwitch){
      typeSwitch = 'SQL'
    } else {
      typeSwitch = 'NSQL'
    }
    var type = typeParam===undefined?typeSwitch:typeParam
    var queryString = typeof queryParam === 'string'?queryParam:query.replace(/\n/g,' ')
    var newQuery = `{user: "${userId}", query: "${queryString}" , type: ${type}}`
    const response = await API.graphql({
      query:  `query RunQuery {
        runQuery(query: ${newQuery})
      }`
    })

    const dataAux = JSON.parse(response.data.runQuery)
    if (type==='NSQL'){
      if (dataAux.status && dataAux.status !== 'success'){
        setShowLoader(false)
        createMessage(JSON.stringify(dataAux.message),dataAux.status)
      } else {
      const data = dataAux.QueryExecution.ResultConfiguration.OutputLocation.split(`${user.identityId}/`)[1]  
      getObject(data)
      }
    } else {
      setDataQuery(dataAux)
      setShowLoader(false)
    }
  }

  const getObject = async (file) => {
    Storage.get(file, { 
        level: 'protected', 
        download: true
    }).then(result =>{
        readExcel(result.Body)
      }) 
}

const getReport = async (file) => {
  var report = file.replace('Reports/', '')
  report = report.replace('.json', '')
  setReportName(report)
  Storage.get(file, { 
      level: 'protected', 
      download: true
  }).then(result =>{
      result.Body.text().then(string => { 
        string = String(string)
        setDataQuery(JSON.parse(string))
        setVisibleQuery(false)
        setVisibleReport(true)
        setShowLoader(false)
      });
    }) 
}

const readExcel = (data) => {
  var oFile = data;
  var reader = new FileReader();
  reader.onload = function(e) {
      var data = e.target.result;
      var cfb = XLSX.read(data, {type: 'binary'});
      cfb.SheetNames.forEach(function(sheetName) {
        var oJS = XLSX.utils.sheet_to_row_object_array(cfb.Sheets[sheetName]); 
        setDataQuery(oJS)
        setShowLoader(false)
      });
  };
  reader.readAsBinaryString(oFile);
}

  const listTables = async () => {
    const response = await API.graphql({
      query:  `query ListTables { listTables }`
    })
  
    const dataAux = JSON.parse(response.data.listTables)
    const data = dataAux.TableList
    var tableList = []
    var dataSetList = []
    if (data){
      for (var i = 0; i < data.length ; i++){
        var table = {
          Name: data[i].Name,
          Description: data[i].Parameters.comment?data[i].Parameters.comment:'',
          Columns: data[i].StorageDescriptor.Columns,
        }
        if (data[i].Parameters.presto_query_id){
          dataSetList.push(table)
        } else {
        tableList.push(table)
        } 
      }
      setTables(tableList)
      setDataSets(dataSetList)
    }

    setShowLoader(false)
    forceUpdate()

  }

  const listQueries = async () => {
    const response = await API.graphql({
      query:  `query ListQueries { listQueries }`
    })

    const dataAux = JSON.parse(response.data.listQueries)
    setQueryList(dataAux)
    forceUpdate()
  }

  const listTablesDW = async () => {

    var userId = user.identityId
    var DS = `{user: "${userId}" }`
    const response = await API.graphql({
      query: `query ListDWDataSet { listDWDataSet(dataSet:${DS}) }` 
    })
  
    const data = JSON.parse(response.data.listDWDataSet)

    var dataSetList = []
    if (data){
      for (var i = 0; i < data.length ; i++){
        var table = {
          Name: data[i].name,
          Description: '',
          Columns: data[i].columns.ColumnList,
        }
          dataSetList.push(table)
 
      }
      setDataSetsDW(dataSetList)
    }

    setShowLoader(false)
    forceUpdate()

  }

  const handleCreateTable = async (data) => {
    setOpenDialogNewTable(false)
    setShowLoader(true)
    var id = data.form.id
    var description = data.form.description
    var delimeter = data.form.delimeter
    var index = data.form.key.lastIndexOf("/");
    var key = data.form.key.substring(0, index) 
    var level = data.form.level
    if (data.form.list) {
        index = key.lastIndexOf("/")
        key = key.substring(0, index) 
    } 
    key = key + '/'
    var userId = user.identityId
    var type = data.form.type
    var columns = JSON.stringify(data.form.columns)
    columns = Buffer.from(columns).toString('base64')
    var partitions = data.form.partitions?JSON.stringify(data.form.partitions):''
    partitions = Buffer.from(partitions).toString('base64')
    var newTable = `{user: "${userId}", id: "${id}", description: "${description}", delimeter: "${delimeter}", level: "${level}", key: "${key}", type: "${type}", columns: "${columns}", partitions: "${partitions}" }`
    const response = await API.graphql({
      query:  `query CreateTable {
        createTable(table: ${newTable})
      }`
    })
    listTables()
  }

  const handleDeleteTable = async (data) => {
    setCheckSwitch(false)
    setQuery('DROP TABLE ' + data)
    setShowLoader(true)
    var userId = user.identityId
    var newQuery = `{user: "${userId}", query: "DROP TABLE ${data}", type:NSQL}`
    const response = await API.graphql({
      query:  `query RunQuery {
        runQuery(query: ${newQuery})
      }`
    })
    listTables()
    setShowLoader(false)
  }

  const handleDeleteTableDW = async (data) => {
    setCheckSwitch(true)
    setQuery('DROP TABLE ' + data)
    setShowLoader(true)
    var userId = user.identityId
    var newQuery = `{user: "${userId}", query: "DROP TABLE ${data}", type: SQL}`
    const response = await API.graphql({
      query:  `query RunQuery {
        runQuery(query: ${newQuery})
      }`
    })
    listTablesDW()
    setShowLoader(false)
  }

  const handlePreviewTable = async (data) => {
    setCheckSwitch(false)
    setReportName('')
    setQuery('SELECT * FROM ' + data + ' LIMIT 10')
    setShowLoader(true)
    var userId = user.identityId
    var newQuery = `{user: "${userId}", query: "SELECT * FROM ${data} LIMIT 10", type: NSQL}`
    const response = await API.graphql({
      query:  `query RunQuery {
        runQuery(query: ${newQuery})
      }`
    })
    const dataAux = JSON.parse(response.data.runQuery)
    const dataFile = dataAux.QueryExecution.ResultConfiguration.OutputLocation.split(`${user.identityId}/`)[1]
    getObject(dataFile)
  }

  const handlePreviewTableDW = async (data) => {
    setCheckSwitch(true)
    setReportName('')
    setQuery('SELECT * FROM ' + data + ' LIMIT 10')
    setShowLoader(true)
    var userId = user.identityId
    var newQuery = `{user: "${userId}", query: "SELECT * FROM ${data} LIMIT 10", type: SQL}`
    const response = await API.graphql({
      query:  `query RunQuery {
        runQuery(query: ${newQuery})
      }`
    })
    const dataAux = JSON.parse(response.data.runQuery)
    setDataQuery(dataAux)
    setShowLoader(false)
  }

  const handleChangeQuery = (data) => {
    setQuery(data)
  }

  const handleCleanQuery = () => {
    setQuery('')
    setDataQuery([])
    setColumns([])  
  }

  const handlePartitions = async (data) => {
    setShowLoader(true)
    var id = data
    var userId = user.identityId
    var table = `{user: "${userId}", id: "${id}"}`
    const response = await API.graphql({
      query:  `query UpdateTablePartitions {
        updateTablePartitions(table: ${table})
      }`
    })
    var messageAux = JSON.parse(response.data.updateTablePartitions)
    createMessage(messageAux.message, messageAux.status )
    setShowLoader(false)
  }

  const closeDialog = async () => {
    setOpenDialogNewTable(false)
    setOpenDialogNewDataSet(false)
    setOpenDialogNewQuery(false)
  }

  const listReports = () => {
       var reports = []
        Storage.list('Reports/',{ level: 'protected' })
          .then(result => {
            for (var i = 0; i < result.length ; i++){
              var key = result[i].key.split('/')
              var report = {
                Id: result[i].key,
                Name: key[key.length - 1].split('.json')[0],
                Description: result[i].Description?result[i].Description:'',
              }
              reports.push(report)
            }
            setReportList(reports)
          })
          .catch(err => console.log(err));
  }

  const handleSelectReport = (data) => {
      setShowLoader(true)
      getReport(data.id)
  }

  const handleSelectQuery = (data) => {
      setQuery(data.id)
      setCheckSwitch(false)
      runQuery(data.id, 'NSQL')
}

  const handleCreateModel = (data) => {
  }

  const createMessage = (text, type) => {
    setMessage(text)
    setMessageType(type)
    setOpenMessage(true)
  }

  const handleOpenCreateDataSet = () => {
    query?setOpenDialogNewDataSet(true)
    :createMessage('Run a query before cretaing a dataset','info')
  }

  const handleCreateDataSet = async (data) => {

    setOpenDialogNewDataSet(false)
    setShowLoader(true)
    var id = data.form.name
    var description = data.form.description
    var userId = user.identityId
    var type = data.form.type
    var format = data.form.format
    var queryParam = query.replace(/\n/g,' ')
    var columns //columnas
    if (data.form.columns) {
    columns = JSON.stringify(data.form.columns)
    columns = Buffer.from(columns).toString('base64')
    }

    var partitions //particiones
    if (data.form.partitions) {
    partitions = JSON.stringify(data.form.partitions)
    partitions = Buffer.from(columns).toString('base64')
    }
   
    var newDS = `{user: "${userId}", id: "${id}", description: "${description}", format: "${format}", query: "${queryParam}", type: "${type}", columns: "${columns}" }`
    const response = await API.graphql({
      query:  `query CreateDataSet {
        createDataSet(dataSet: ${newDS})
      }`
    })
    const dataAux = JSON.parse(response.data.createDataSet)
    if (dataAux.status && dataAux.status !== 'success'){
      setShowLoader(false)
      createMessage(JSON.stringify(dataAux.message),dataAux.status)
    } else {
      listTables()
      listTablesDW()
    }  
  }

  const onCloseMessage = () => {
    setOpenMessage(false)
  }

  const handleChangeSwitch = (event) => {
    setCheckSwitch(event.target.checked)
  }

  const handleCreateQuery = async (data) => {

    setOpenDialogNewQuery(false)
    setShowLoader(true)
    var type
    if(checkSwitch){
      type = 'SQL'
    } else {
      type = 'NSQL'
    }
    var queryString = query.replace(/\n/g,' ')
    var newQuery = `{user: "${user.identityId}", id: "${data.form.id}",description: "${data.form.description}", query: "${queryString}" , type: ${type}}`
    const response = await API.graphql({
      query:  `query CreateQuery {
        createQuery(query: ${newQuery})
      }`
    })
     const dataAux = JSON.parse(response.data.createQuery)
     listQueries()
     setShowLoader(false)
  }

  const handleDeleteReport = async (id) => {
    setShowLoader(true)
    await Storage.remove(id, { level: 'protected' });
    listReports()
    setShowLoader(false)
  }

  const handleCreateReport = async () => {
    listReports()
  }
 
  return (
    <div className = {classes.root}>
     <Loader show = {showLoader}/>
     <Message open = {openMessage} message = {message} type = {messageType} onClose={onCloseMessage}/>
     <SplitterLayout  secondaryInitialSize = {70} percentage = {true}>
       {visibleCatalog?
        <div className = {classes.content}>
        <Typography className = {classes.typographyT}>Data Lake</Typography>
        <ListAccordion 
            title = 'Tables' 
            detail={true} 
            onPartitions = {handlePartitions} 
            onPreview={handlePreviewTable} 
            onDelete={handleDeleteTable} 
            onCreate = {dialogCreateTable} 
            data = {tables}
            list = {[ {
                  id: 0,
                  label: 'Preview'},
                  {id: 1,
                  label: 'Partitions'},
                  {id: 2,
                  label: 'Delete'}]}/>
        <ListAccordion 
            title = 'Datasets'  
            data = {dataSets} 
            detail={true} 
            onPreview={handlePreviewTable} 
            onDelete={handleDeleteTable}
            list = {[ {
              id: 0,
              label: 'Preview'},
              {id: 1,
              label: 'Share'},
              {id: 2,
              label: 'Delete'}]}/>
         <ListAccordion 
              title = 'Queries'
              data={queryList} 
              onSelect={handleSelectQuery}/>
         <Divider className = {classes.divider}/>  
         <Typography className = {classes.typographyT}>Data Warehouse</Typography>   
         <ListAccordion 
            title = 'Datasets'  
            data = {dataSetsDW} 
            detail={true} 
            onPreview={handlePreviewTableDW} 
            onDelete={handleDeleteTableDW}
            list = {[ {
              id: 0,
              label: 'Preview'},
              {id: 1,
              label: 'Share'},
              {id: 2,
              label: 'Delete'}]}/>
        <ListAccordion title = 'Queries'/>
        <ListAccordion title = 'ML Models'
           detail={true} 
           onCreate={handleCreateModel}/>
            <Divider className = {classes.divider}/>
         <Typography className = {classes.typographyT}>Visualizations</Typography>    
        <ListAccordion title = 'Visualizations' 
            data={reportList} 
            onSelect={handleSelectReport}
            onDelete = {handleDeleteReport}
            list = {[
              {id: 0,
              label: 'Share'},
              {id: 1,
              label: 'Delete'}]}/>
        </div>
        :null}
        <div >
        <div className =  {classes.buttonLeft} >
         <IconButton
             color="secondary" 
             onClick={() => {
               setVisibleCatalog(visibleCatalog?false:true)
              }
             }>
             {visibleCatalog?<Back/>:<Forward/>}
         </IconButton>
         <Divider/>
        </div>
        <SplitterLayout vertical={true} secondaryInitialSize = {65} percentage = {true}> 
        {visibleQuery?
        <div className = {classes.content}>  
        <div className = {classes.buttonGroup}>
         <IconButton  
             color="secondary" 
             onClick={() => {
               setVisibleReport(visibleReport?false:true)
              }
             }>
             {visibleReport?<AspectRatioIcon/>:<ClearIcon/>}
         </IconButton>
        </div>
       
        <div className = {classes.grid}>
        <div className = {classes.buttonGroupLeft}>
        <Button  className ={classes.buttonPrimary} color="primary" onClick={runQuery}>
              Run query
        </Button>
        <Typography component="div" className={classes.switch}>
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>Data Lake</Grid>
          <Grid item>
            <Switch checked={checkSwitch} onChange={handleChangeSwitch} name="checkedC" />
          </Grid>
          <Grid item>Data WH</Grid>
        </Grid>
      </Typography>
        </div>
        <div className = {classes.buttonGroup}>
        
            <Button className ={classes.button} color="secondary" onClick={() => setOpenDialogNewQuery(true)}>
              Save query
            </Button>
            <Button className ={classes.button} color="secondary" onClick={handleOpenCreateDataSet}>
              Create dataset
            </Button>
            <Button  className ={classes.button} color="secondary" onClick={handleCleanQuery}>
              Clean data
        </Button>
          </div>
          </div>
            <AceEditor
              className= {classes.editorText}
              placeholder=""
              mode= "sql"
              height = "30vh"
              theme={checkSwitch?"solarized_light":"solarized_dark"}
              name="edit"
              fontSize={18}
              showPrintMargin={false}
              showGutter={true}
              highlightActiveLine={true}
              value={query}
              onChange={handleChangeQuery}
              setOptions={{
                  enableLiveAutocompletion: true,
                  showLineNumbers: true,
              }}
            />
          </div>:null}
          {visibleReport?  
        <div className = {classes.content}>
        <div className = {classes.buttonGroup}>
         <IconButton 
             color="secondary" 
             onClick={() => {
               setVisibleQuery(visibleQuery?false:true)
              }
             }>
             {visibleQuery?<AspectRatioIcon/>:<ClearIcon/>}
         </IconButton>
        </div>
            <Typography className = {classes.typographyL}>{reportName}</Typography>
            <Flexmonster data={dataQuery} onCreate = {handleCreateReport}/>
         </div>:null}
         </SplitterLayout>
        </div>
      </SplitterLayout>
      <DialogSlide 
          open = {openDialogNewTable} 
          mode = 'formCreateTable' 
          onConfirm = {handleCreateTable}
          onCancel = {closeDialog}
        />
        <DialogSlide 
          open = {openDialogNewDataSet} 
          mode = 'formCreateDataSet' 
          onConfirm = {handleCreateDataSet}
          onCancel = {closeDialog}
          default = {{
            columns :  dataQuery?dataQuery[0]:null
          }}
        />
         <DialogSlide 
          open = {openDialogNewQuery} 
          mode = 'formCreateQuery' 
          onConfirm = {handleCreateQuery}
          onCancel = {closeDialog}
        />
    </div>
  )
}
