const style = {
    root: {

      "& .MuiListItemText-primary":{
        fontWeight: 'bold',
        fontSize: '16px'
      },
      "& .MuiPaper-root":{
        padding: '10px'
      },
      "& .layout-splitter:hover":{
        backgroundColor: '#299891'
      },
      "& .splitter-layout":{
        width: 'calc(100% - 80px)',
        height: 'calc(100% - 80px)',
      }
      },
      content: {
        padding: '10px',
      },

      content2: {
        padding: '20px',
      },

      buttonLeft:{
        textAlign: 'start',
        height: '40px',
        margin: '10px'
      },
}

export default style