import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import {popoverList} from './config';
import React from 'react';
import PopoverList from "../Popover/index";
import {Accordion, AccordionSummary, AccordionDetails, Typography, List, ListItem, 
ListItemText, FormControlLabel , Link, InputBase, IconButton, MenuItem,ListItemSecondaryAction } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => (style));

export default function ListAccodion(props) {

  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [dataFiltered, setDataFiltered] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() =>  {
    if (props.data){
    setData(props.data)
    setDataFiltered(props.data)
    }
  }, [props.data]);

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleSelected = (id) => {
    if (props.onSelect) {
      props.onSelect({id: id})
    }
  }

  const handleCreate = (event) => {
      props.onCreate('Create')
  }

  const handleDelete = (id) => {
    if(props.onDelete){
      props.onDelete(id)
    }
  }

  const handlePopoverClose = () => {
      setAnchorEl(null)
  };

  const handleSearch = (event) => {
    var dataFilteredAux = data.filter( i => i.Name.includes( event.target.value ) || i.Description.includes( event.target.value ))
    setDataFiltered(dataFilteredAux)
  }

  const handlePopoverClick = (data) => {
    var element = anchorEl.getAttribute('aria-label')
    setAnchorEl(null)
    if (data.option==='Preview'){
      if(props.onPreview){
        props.onPreview(element)
      }
    } else if (data.option==='Delete'){
      if(props.onDelete){
        props.onDelete(element)
      }
    } else if (data.option==='Partitions'){
      if(props.onPartitions){
        props.onPartitions(element)
      }
    } 
};

  return (
    <div className={classes.root}>
     <PopoverList
        popoverData = {props.list?props.list:popoverList} 
        anchorEl = {anchorEl} 
        onClose = {handlePopoverClose}
        onClick = {handlePopoverClick}
     />
     <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panela-content"
          id="panela-header"
        >
        <Typography className = {classes.title}>{props.title + ' (' + data.length.toString() + ')'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
         
          <div>
          <div className = {classes.options}> 
          {props.onCreate? 
          <Link
              component="button"
              variant="body2"
              onClick={handleCreate}
            >
              Create
            </Link>:null
          }  
          </div>
          <div className={classes.inputContainer}>
            <InputBase
              className={classes.input}
              placeholder="search"
              inputProps={{ 'aria-label': 'search' }}
              onChange={handleSearch}
              margin='dense'
            />

          </div>
            <List dense={true}  disablePadding >
              {dataFiltered?dataFiltered
                 .map((item, index) => (
                <div>  
                {props.detail && props.detail===true?    
                <ListItem className={classes.item}>
                  <div>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panelb-content"
                      id="panelb-header"
                    >
                      <FormControlLabel
                        aria-label={item.Name}
                        onClick={handleClick}
                        onFocus={(event) => event.stopPropagation()}
                        control={<MoreVert/>}
                      />
                      <Typography>{item.Name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className = {classes.list}>
                     <ListItemText secondary={item.Description?item.Description:''}/>
                     {item.Columns?
                      <List dense={true}>
                        {item.Columns.map((column) => (
                          <ListItem>
                            <ListItemText
                              primary= {column.Name?`${column.Name}  - ${column.Type}`:`${column.name}  - ${column.typeName}`}
                              secondary= {column.Comment}
                            />
                          </ListItem>
                          ))}
                      </List>:null}
                    </AccordionDetails>
                    </Accordion>
                    </div>
                </ListItem>:
                  <MenuItem 
                    className = {classes.itemList} 
                    onClick={() => handleSelected(item.Id)}
                    divider={true}>
                    <ListItemSecondaryAction style={{
                         left: '10px',
                         top: '25px'
                        }}>
                         <MoreVert 
                            aria-label={item.Id}
                            onClick={handleClick}
                            onFocus={(event) => event.stopPropagation()}
                          
                        />
                    </ListItemSecondaryAction>  
                    <ListItemText style={{
                         marginLeft: '45px'
                        }}
                        primary= {item.Name}
                        secondary= {
                        <div>
                        <Typography>{item.Description?item.Description:''}</Typography>  
                        <div></div>
                        </div>}
                    />
                  </MenuItem>}
                </div>  
              )):null}
            </List>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
