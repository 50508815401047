import { Hub, Storage} from 'aws-amplify';
import React from "react"; 
import FileBrowser, {Icons} from 'react-keyed-file-browser'
import Moment from 'moment'
import './react-keyed-file-browser.css';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import {ViewComfyOutlined,NoteOutlined, DescriptionOutlined, MusicNoteOutlined, MovieOutlined, FolderOpen, DeleteOutlined, AutorenewOutlined, NoteAddOutlined, InsertDriveFileOutlined, ImageOutlined, PictureAsPdfOutlined, EditOutlined, Folder, CreateNewFolder} from '@material-ui/icons/';
import {makeStyles} from '@material-ui/core/styles';
import {Switch, Typography, Grid} from '@material-ui/core';
import style from './style';
import Loader from "../Loader/index";
import ColumnList from "../ColumnList/index";
import AuthContext from "../../context/AuthContext";
import awsmobile from "../../aws-exports"

const useStyles = makeStyles((theme) => (style));

const FileSystem = ({dataCallback, editable, columns, columnsCallback}) => {

    const classes = useStyles();
    const files = []
    const [data, setData] = React.useState([]); // Datos
    const [path, setPath] = React.useState('');
    const [storage, setStorage] = React.useState('public');
    const [fileKey, setFileKey] = React.useState('');
    const [checkSwitch, setCheckSwitch] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(false);
    const [showColumns, setShowColumns] = React.useState(false);
    const {user} = React.useContext(AuthContext)

    React.useEffect(() => {
      setShowLoader(true)
      StorageList()
    }, []);

    // Escondemos el input y lo llamamos por evento
    const hiddenFileInput = React.useRef(null); 
    const handleonUpload = event => {
      hiddenFileInput.current.click();
    }

    // GET OBJECTS FROM S3
    const StorageList = async (level) => {

      var levelOption = level?level:storage
      //We do it via SDK beacuse Storage class doesn't recover more than 1000 objects
      var AWS = require('aws-sdk');
      AWS.config.region=awsmobile.aws_user_files_s3_bucket_region;
      AWS.config.credentials = user.credentials
      var allKeys = []; 
      var params = {} 
      params.Prefix= levelOption==='public'?'public/':`${levelOption}/${user.identityId}/`;
      params.Bucket = awsmobile.aws_user_files_s3_bucket
      var isTruncated = true

      do {
       
      var data = await new AWS.S3().listObjectsV2(params).promise()
      var contents = data.Contents;
      contents.forEach(function (item) {
        var file = {
        key : item.Key.replace(params.Prefix, ''),
        modified : item.LastModified,
        size : item.Size
        } 
        allKeys.push(file);
      });
      isTruncated = data.IsTruncated

      if (isTruncated) {
        params.ContinuationToken = data.NextContinuationToken;
      }

      } while (isTruncated===true)
     
      setData(allKeys)
      setShowLoader(false)

    }

    // Formatear el listado de S3 para adaptarlo al componente File system
    function processStorageList(results) {
     
      const add = (item, files) => {
        var file = {
          key : item.key,
          modified : item.lastModified,
          size : item.size 
        }
        files.push(file)
      };
      results.forEach(item => add(item, files));
      setData(files)
      setShowLoader(false)
    }

        // LOAD FUNCION
    async function onChange(e) {
      setShowLoader(true)
      var file = e.target.files[0];
      var key = path + file.name;
      try {
        await Storage.put(key, file, {
          level: storage,
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
         },
        });
        StorageList()
      } catch (error) {
        console.log('Error uploading file: ', error);
      }  
    }

    const handleCreateFolder = async (key) => {
       await Storage.put(key,key, { level: storage });
       StorageList()
    }


     const handleCreateFiles = async (files, prefix) => {
      for (var i = 0; i < files.length; i ++) {
      await Storage.put(prefix + files[i].name, files[i], { level: storage });
      StorageList()
      }
    }

    const handleRenameFolder = async (oldKey, newKey) => {
        data.map((file) => {
          setShowLoader(true)
          if (file.key.substr(0, oldKey.length) === oldKey) {
             file.key.replace(oldKey, newKey)
             handleRenameFile(file.key,file.key.replace(oldKey, newKey))
          } 
        })
        //StorageList()
    }

    const handleRenameFile = async (oldKey, newKey) => {
      setShowLoader(true)
     var file = await Storage.get(oldKey, { download: true, level: storage }) 
     await Storage.remove(oldKey, { level: storage });
     await Storage.put(newKey, file.Body, { level: storage });
     StorageList()
    }

    const handleDeleteFolder = async (folderKey) => {
      await Storage.remove(folderKey, { level: storage });
      StorageList()
    }

    const handleSelectFolder = (folderKey) => {
      setPath(folderKey.key)
    }

    const handleSelectFile = (key) => {
      var file = key
      file.extension = file.key.split('.').pop()
      if (columns === true){
        setShowColumns(true)
        setFileKey(key) 
      }
      file.level = storage
      dataCallback(file)
    }

    const handleDeleteFile = async (fileKey) => {
      await Storage.remove(fileKey, { level: storage });
      StorageList()
    }

    const handleColumns = (data) => {
      columnsCallback(data)
    }

    const handleColumnsClose = (data) => {
      setShowColumns(false)
    }

    const handleChangeSwitch = (event) => {
      setCheckSwitch(event.target.checked)
      var level = ''
      if (event.target.checked){
       level = 'protected'
      } else {
        level = 'public'
      }
      setStorage(level)
      StorageList(level)
    }

    return (
      <>  
        <input 
            type="file" 
            style={{display: 'none'}} 
            ref={hiddenFileInput}
            onChange={onChange}
        />

        <Loader show = {showLoader}/>
        {editable &&
        <div className = {classes.buttonGroup}>
            <Button  className ={classes.button} color="secondary" onClick={handleonUpload}>
              CREATE
            </Button>
            <Button  className ={classes.button} color="secondary" onClick={handleonUpload}>
              UPLOAD
            </Button>
        </div>}
        {!showColumns?
        <div>
            <Typography component="div" className={classes.switch}>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>Public</Grid>
                <Grid item>
                  <Switch checked={checkSwitch} onChange={handleChangeSwitch} name="checkedC" />
                </Grid>
                <Grid item>Private</Grid>
              </Grid>
            </Typography>
        <FileBrowser
            files={data?data:files}   
            detailRenderer={() => null}
            icons={{
              File: <InsertDriveFileOutlined className ={classes.icon}/>,
              Image: <ImageOutlined className ={classes.icon}/>,
              PDF: <PictureAsPdfOutlined />,
              Video: <MovieOutlined className ={classes.icon}/>,
              Audio: < MusicNoteOutlined className ={classes.icon} />,
              Word: < DescriptionOutlined className ={classes.icon} />,
              Excel: < ViewComfyOutlined className ={classes.icon}/>,
              PowerPoint: <NoteOutlined className ={classes.icon}/>,
              //Text: <className ={classes.icon} />,
              Rename: <EditOutlined className ={classes.icon} />,
              Folder: <Folder className ={classes.icon} />,
              FolderOpen: <Folder className ={classes.icon} />,
              Delete: <DeleteOutlined className ={classes.icon}/>,
              Loading: <AutorenewOutlined className ={classes.icon}/>
            }}

            onCreateFolder={editable?handleCreateFolder:''}
            onCreateFiles={editable?handleCreateFiles:''}
            onMoveFolder={editable?handleRenameFolder:''}
            onMoveFile={editable?handleRenameFile:''}
            //onRenameFolder={handleRenameFolder}
            //onRenameFile={handleRenameFile}
            onDeleteFolder={editable?handleDeleteFolder:''}
            onDeleteFile={editable?handleDeleteFile:''}
            onSelectFolder={handleSelectFolder}
            onSelectFile={handleSelectFile}
        />
        </div>
        :<ColumnList comment={true} close= {handleColumnsClose} file={fileKey} callback = {handleColumns}></ColumnList>}
      </>
    )
}

export default FileSystem