import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import SearchCSS from './SearchCSS.js'
import {useTranslation} from "react-i18next";

const useStyles = SearchCSS;

export default function ToolBar({searchField, setSearchField}) {

  const classes = useStyles();
  const [t, i18n] = useTranslation();

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon/>
      </div>
      <InputBase
        placeholder={t("Search")}
        value={searchField}
        onChange={setSearchField}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{'aria-label': 'search'}}
      />
    </div>)
}
