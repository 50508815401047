import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';
import Loader from "../Loader/index";
import Message from '../Message/index'
import Editor from '../Editor/index'
import AuthContext from "../../context/AuthContext";
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => (style));

export default function Template(props) {

  const classes = useStyles();
  const [showLoader, setShowLoader] = React.useState(false);
  const [openMessage, setOpenMessage] = React.useState(false); // Message
  const [message, setMessage] = React.useState(''); // Texto message
  const [messageType, setMessageType] = React.useState(''); // Tipo message
  const {user} = React.useContext(AuthContext)

  React.useEffect(() =>  {
     getDagDetail(props.data.dag_id)
  }, []);

  const onCloseMessage = () => {
    setOpenMessage(false)
  }

  const createMessage = (text, type) => {
    setMessage(text)
    setMessageType(type)
    setOpenMessage(true)
  }

  const getDagDetail = (id) => {

  }

  return (
    <div className = {classes.root}>
     <Message open = {openMessage} message = {message} type = {messageType} onClose={onCloseMessage}/>
     <Loader show = {showLoader}/>
     <Grid item  sm container className={classes.form} spacing={3}>
            <Grid item xs={12} container direction="column" spacing={2}>
              <Editor mode="python" height = '65vh' theme= 'xcode' />
            </Grid>
      </Grid>          
    </div>
  )
}
