
const colors = ['#cdbe2a', '#bdbdbd', '#008FFB', '#00E396']
const chartOptions = {

    gauge:{
    chart: {
      width: 200,
      type: 'radialBar'
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        offsetX: 40,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '10%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          }
        }
      }
    },
    
    colors: colors,
    labels: ['Total','Admins', 'Authors', 'Readers'],

    legend: {
      show: true,
      floating: true,
      fontSize: '16px',
      position: 'left',
      offsetX: -15,
      offsetY: 15,
      fontSize: '14px',
      labels: {
        useSeriesColors: true
      },
      markers: {
        size: 0
      },
      formatter: function(seriesName, opts) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + " %"
      },
      itemMargin: {
        vertical: 3
      }
    },
    responsive: [{
      breakpoint: 980,
      options: {
        legend: {
            show: false
        }
      }
    }]
    },

    bar: {
            chart: {
              type: 'bar',
              toolbar: {
                show: false,
              }  
            },
            grid: {
              show: false
            },
            colors: colors,
            plotOptions: {
              bar: {
                columnWidth: '45%',
                distributed: true,
              }
            },
            dataLabels: {
              enabled: true
            },
            legend: {
              show: false
            },
            yaxis: {
                labels: {
                    show: false
                }
            } ,   
            xaxis: {
              categories: [
                'Total',  
                'Admins',
                'Authors',
                'Readers'
              ],
              labels: {
                style: {
                  colors: colors,
                  fontSize: '14px'
                }
              }
            }
          },
        
    }
  



export default chartOptions