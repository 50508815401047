import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';

const useStyles = makeStyles((theme) => (style));

export default function Usage() {

    const classes = useStyles();
    
  return (
    <>
              Aqui va el dashboard de uso
    </>
  )
}
